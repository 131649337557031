/* Mentor Section */
.mentor-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 5%;
    padding-bottom: 20%;
    position: relative;
    padding-top: 40px;
    background: linear-gradient(90deg, #C6E894, #ffffff, #99C05F);
}

.mentor-title {
    width: 100%;
    text-align: center;
    margin: 40px auto;
    position: relative;
    z-index: 1;
}

.mentors-heading {
    font-size: clamp(3.3rem, 6vw, 2rem);  /* Increased base size */
    text-align: center;
    margin: 2rem auto;
    padding: 0;
    color: #333;
    font-weight: bold;
    max-width: 90%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    gap: 1rem;
    white-space: nowrap;  /* Prevents wrapping */
}

.highlight {
    color: #4caf50;
    font-weight: bold;
    font-size: inherit;  /* Ensures the highlight text matches the parent size */
}

/* Static Left-Side Mentor Image */
.mentor-image {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 400px;
    margin-right: 100px;
}

.mentor-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-top: 250px;
    margin-left: 150px;
}

/* Right Side - Animated Mentor Images */
.right-mentor {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 800px;
    overflow: hidden;
    margin-left: 400px;
}

/* Mentor Card Styles */
.mentor-card {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.5s ease-in-out, transform 2s ease-in-out;
}

.mentor-card.enter {
    opacity: 1;
    transform: translateY(0);
}

.mentor-card.exit {
    opacity: 0;
    transform: translateY(-20px);
}
.mentor-image-animated {
    width: 70%;
    height: auto;
    border-radius: 8px;
}

.mentor-card.middle {
    margin-left: 150px;
    width: 100%;
}


@media screen and (max-width: 992px) {
    .right-mentor {
        margin-left: 200px;
    }
    .mentor-card.middle {
        margin-left: 100px;
    }
}

@media screen and (max-width: 768px) {
    .mentor-section {
        padding-bottom: 200px;
    }
    .mentor-image {
        position: relative;
        margin: 0 auto;
        margin-bottom: 50px;
    }
    .mentor-image img {
        margin: 20px auto;
        margin-top: 50px;
    }
    .right-mentor {
        margin-left: 0;
        align-items: center;
    }
    .mentor-card.middle {
        margin-left: 0;
    }
}

@media screen and (max-width: 480px) {
    .mentor-section {
        padding: 10px;
        padding-bottom: 100px;
        margin-top: 50px;
    }
    .mentors-heading {
        font-size: clamp(2.2rem, 6vw, 2rem);
        text-align: center;
        margin: .1rem auto;
        padding: 0;
        color: #333;
        font-weight: bold;
        max-width: 90%;
        position: absolute; 
        left: 50%;
        transform: translate(-50%, 0);  
        display: flex;
        justify-content: center;
        align-items: center; 
        gap: 1rem;
        white-space: nowrap;
        width: fit-content; 
      }

    .mentor-title {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .mentor-image {
        max-width: 230px;
        position: static;
        margin: 0 auto;
    }

    .mentor-image img {
        margin: 20px auto;
        width: 100%;
    }

    .right-mentor {
        width: 100%;
        align-items: center;
        gap: 20px;
    }

    .mentor-image-animated {
        width: 85%;
        max-width: 280px;
    }

    .mentor-card {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .mentor-card.middle {
        width: 100%;
        margin-left: 0;
    }
}
@media screen and (max-width: 360px) {
    .mentor-section {
        padding: 10px;
        padding-bottom: 100px;
        margin-top: 50px;
    }

    .mentor-title {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .mentors-heading {
        font-size: clamp(2.2rem, 6vw, 2rem);
        text-align: center;
        margin: .1rem auto;
        padding: 0;
        color: #333;
        font-weight: bold;
        max-width: 90%;
        position: absolute; 
        left: 50%;
        transform: translate(-50%, 0);  
        display: flex;
        justify-content: center;
        align-items: center; 
        gap: 1rem;
        white-space: nowrap;
        width: fit-content; 
      }
    .mentor-image {
        max-width: 230px;
        position: static;
        margin: 0 auto;
    }

    .mentor-image img {
        margin: 20px auto;
        width: 100%;
    }

    .right-mentor {
        width: 100%;
        align-items: center;
        gap: 20px;
    }

    .mentor-image-animated {
        width: 85%;
        max-width: 280px;
    }

    .mentor-card {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .mentor-card.middle {
        width: 100%;
        margin-left: 0;
    }
}

