.program-card {
  background-color: #526E8A;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
}


.batch-time {
  background-color: rgb(46, 80, 100);
  color: white;
  font-size: 14px;
  padding: 10px;
  width: 212px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: heartbeat 1.2s infinite;
  margin-bottom: 10px
}

/* @keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
} */




/* Slideshow container */
.slideshow-container5 {
  position: relative;
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: transparent;
  perspective: 1000px; /* Perspective for 3D effect */

}

/* Overlay for all images and squares */
.image-overlay {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Green squares in the background */
.green-square {
  position: absolute;
  background-color: #CFFB8D; /* Green color */
  z-index: -1; /* Moves squares behind images */
  border-radius: 10px; /* Optional for rounded corners */

}

/* Positions for green squares */
.green-square.square-1 { width: 200px; height: 200px; top: 9%; left: 30%; }
.green-square.square-2 { width: 220px; height: 220px; top: 20%; right: 30%; }
.green-square.square-3 { width: 170px; height: 170px; bottom: 15%; left: 15%; }
.green-square.square-4 { width: 120px; height: 120px; bottom: 10%; right: 5%; }
.green-square.square-5 { width: 60px; height: 60px; top: 40%; left: 10%; transform: translateY(-50%); }
.green-square.square-6 { width: 90px; height: 90px; top: 75%; right: 40%; }
.green-square.square-7 { width: 80px; height: 80px; bottom: 30%; left: 40%; }

/* Shared image-box properties */
.image-box {
  position: absolute;
  overflow: hidden;
  border-radius: 10px; /* Rounded corners */
  z-index: 1; /* Ensure images are above green squares */
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  /* transform-origin: center;
  transform-style: preserve-3d; */
  box-shadow: 10px 15px 5px rgba(0, 0, 0, 0.2); /* Drop shadow for 3D depth */
  
}

.image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 3D Floating Effect on Hover */
.image-box:hover {
  transform: perspective(1000px) rotateX(10deg) rotateY(10deg) scale(1.05);
  box-shadow: 15px 20px 30px rgba(0, 0, 0, 0.5);
}

/* Center Image */
.image-box.center { width: 370px; height: 300px; top: 50%; left: 50.5%; transform: translate(-50%, -50%); }
/* Top-Left Image */
.image-box.top-left { width: 250px; height: 250px; top: 15%; left: 20%; }
/* Top-Right Image */
.image-box.top-right { width: 260px; height: 200px; top: 28%; right: 18%; }
/* Bottom-Left Image */
.image-box.bottom-left { width: 140px; height: 140px; bottom: 28%; left: 27%; }
/* Bottom-Right Image */
.image-box.bottom-right { width: 300px; height: 250px; bottom: 6%; right: 15%; }
/* Far-Left Image */
.image-box.far-left { width: 270px; height: 200px; top: 66%; left: 7%; transform: translateY(-50%); }
/* Far-Right Image */
.image-box.far-right { width: 120px; height: 100px; top: 40%; right: 9%; transform: translateY(-50%); }



/* .image-box {
  position: absolute;
  transition: transform 20.5s ease-in-out;
  animation: shrink-grow 10s infinite;
} */

/* @keyframes shrink-grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
} */







.program-card:hover {
  background-color: #101902;
  transform: scale(1.05);
  color: #0E2031;
}

.program-card h4,
.program-card p {
  margin: 10px 0;
  font-size: 1rem;
}

/* Program Card Container */
.program-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0 80px;
  margin: 0 auto;
  padding: 0px 160px;
}

.program-card {
  flex: 1 1 calc(5% - 1rem); /* Four cards per row */
  padding: 1.3rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s;
}

/* Program Image */
.program-image {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.program-image img {
  max-width: 100%;
  border-radius: 8px;
}

.program-image1 {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.program-image1 img {
  max-width: 100%;
  border-radius: 8px;
}

/* Program Details */
.program-details {
  width: 60%;
  color: #333;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.program-details h4 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.program-details p {
  font-size: 1.1rem;
}


.gallery span {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.gallery span:nth-child(1) {
  transform: rotateY(0deg) translateZ(100px);
  opacity: 1;
}

/* Slideshow Container */
.slideshow-container5 {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background: linear-gradient(90deg, #C6E894, #ffffff, #99C05F); */
  margin-bottom: 200px;
}

.slideshow-image5 {
  max-width: 30%;
  margin: 100px 10px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.8);
}
.arrow-button {
  background-color: grey;
  color: white;
  border: none;
  padding: 45px; /* Increased padding to make it more round */
  cursor: pointer;
  font-size: 44px;
  border-radius: 70%; /* Makes the button round */
  margin: 0 8px;
  transition: background-color 0.3s;
  
}

.arrow-button:hover {
  background-color: rgb(0, 0, 0); /* Darker grey on hover */
}

/* Enquiry Section */
.enquiry-container5 {
  text-align: center;
  margin: 2rem 0;
}

.enquiry-heading5 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.form-group5 {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #ccc;
}

input, select, textarea {
  width: 100%;
  padding: 0.6rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.15);
  color: #333;
  font-size: 0.4rem;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
}

input:focus, select:focus, textarea:focus {
  border-color: #76b0f9;
  outline: none;
}

.submit-button5 {
  background: #76b0f9;
  color: white;
  padding: 0.4rem 1.4rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.submit-button5:hover {
  background: #6a9edb;
  color: #000;
}

.enquiry-heading{
  font-family: poppins;
  color: #c3e47d;
}


.enquiry-form-container5 {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px; /* Limit form width */
  margin: 00px auto; /* Center the form */
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  color: white;
  margin-bottom: 300px;
}

.enquiry-form5 {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  background-color: #547698;
  padding: 40px;
  height: 400px;
  box-shadow: 40px 40px 15px rgba(0, 0, 0, 0.4);
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-input, .form-select, .form-textarea {
  width: 100%;
  padding: 0rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
}

.form-input:focus, .form-select:focus, .form-textarea:focus {
  outline: none;
  border-color: #28a745;
  box-shadow: 0 0 5px rgba(40, 167, 69, 0.5);
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
}

.form-textarea {
  min-height: 100px;
}

.submit-btn5 {
  background-color: #C5F678;
  color: rgb(15, 2, 2);
  font-size: 1.2rem;
  padding: 1rem;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 50%;
  text-align: center;
  margin: 0 auto; /* Centers the button */
  display: block; /* Ensures proper centering as a block element */
  box-shadow: 0 0 15px rgba(197, 246, 120, 0.5); /* Initial glow */
  margin-top: 20px;
}

.submit-btn5:hover {
  background-color: #C5F678;
  color: rgb(0, 0, 0); /* Changes text color for better contrast */
  box-shadow: 0 0 20px #C5F678; /* Enhanced glow on hover */
}


input, select, textarea {
  width: 90%; /* Center and keep fields away from container edges */
  padding: 1rem;
  border: none;
  border-radius: 10px; /* Rounded corners for inputs */
  color: black; /* White text color */
  font-size: 1rem;
  box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2); /* Inner shadow for depth */
  margin: 1px auto; /* Center the input fields within the container */
  display: block; /* Ensures inputs take full width and stay centered */
  color: #101902;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}

select{
  width: 98%; 
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}
input::placeholder, select::placeholder, textarea::placeholder {
  color: white; /* White placeholder text */
  opacity: 0.7; /* Slightly transparent for placeholder */
  font:poppins;
  
}

input:focus, select:focus, textarea:focus {
  outline: 2px solid #CFFB8D; /* Green outline on focus */
}

.submit-button:hover {
  background: #6a9edb;
}


.white-square{
  margin-top: -11px;
}



/* Programs Title */
.programs-title {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  color: #333;
  letter-spacing: 1px;
  margin-bottom: 0px;
  font: poppins;
}

.text-black {
  color: #333;
  font-size: 1.3em;
  padding: 0 4px;
}

.text-green {
  color: #c3e47d;
  font-size: 1.4em;
}


/* Program Details with Glass Gaze Effect */
.program-details {
  width: 60%;
  color: #fff;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.15); /* Transparent white background */
  backdrop-filter: blur(8px); /* Blurred background to simulate frosted glass */
  border-radius: 15px; /* Rounded corners */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border with opacity */
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Soft shadow to add depth */
  height: 300px;

  
}

.program-details h3,
.program-details p {
  color: rgb(0, 0, 0); /* White text for better contrast */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Soft glow effect on text */
  
}


/* Program Details Container */
.program-details-container {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding: 20px;
  border-radius: 12px;
  width: 80%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  box-shadow: 0px 6px 15px #0E2031(0, 0%, 0%, 0.15); /* Enhanced shadow for a modern feel */
  transition: all 0.3s ease-in-out; /* Smooth effect */
}

.program-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}

.program-card:hover {
  background-color: #C6E894;
  transform: scale(1.05);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2); /* Add hover shadow */
  color: #0E2031;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}

/* Prevent jump effect */
.program-card:active {
  transform: scale(1.02); /* Slight press effect */
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}


.details-btn,
.cenroll-btn{
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px; /* Add border radius for better button styling */
}

.details-btn:hover,
.cenroll-btn:hover {
  background-color: #45a049;
}

.program-image {
  margin-top: 5px;
  width: 45%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border-radius: 8px;
}
.program-image1 {
  margin-top: 45px;
  width: 45%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border-radius: 8px;
}

.program-details {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure space between elements */
  padding-left: 20px;
}

.program-name-details {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff; /* Make text color more readable on dark background */
}

.program-description {
  font-size: 1.1rem;
  margin-top: 10px;
  color: #ddd; /* Lighter color for description */
}

.program-details p {
  margin: 10px 0;
  color: rgb(0, 0, 0); /* Subtle text color for readability */
}

.program-name-details {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
  height: 100%;           /* Set the height if needed */
  text-align: center;     /* Ensures text alignment inside */
}


/* TOP HEADING CSS  */
.text-green1{
  color: #ffffff;
  font-size: 1.5em;
}
.text-black1{
  color:#ffffff;
  font-size: 1.5em;
}

.programs-title2 {
  margin-top: 100px;
  font-weight: 900;
  text-align: center;
  padding-top: 20px;
  color: rgb(6, 6, 6);
  background: linear-gradient(0deg, #ABDE75, #2C4756);
  font-size: 1.5em;
  background-size: 300% 300%;
  animation: gradientAnimation 6s ease infinite;
}

.section-title5 {
  text-align: center; /* Center the title */
  margin-top: 70px; /* Extra space at the top */
  margin-left: auto;
  margin-right: auto;
  padding: 0.2rem 0; 
  font-size: 1.9em;
  color: #0E2031;
  max-width: 800px; /* Optional: Controls width for better layout */
}



.program-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%; /* Adjust width for tablet */
  max-width: 400px; /* Set a larger maximum width */
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  margin-top:50px;
  height: 260px;


  background-color: #fff;  /* White background to contrast the border */
  padding: 20px;
  border-radius: 10px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);  /* Inward shadow effect */
  border: 2px solid #ccc;  /* Light grey border */
}






@media screen and (min-width: 768px) and (max-width: 1024px){
  .program-section{
    padding: 30px;
  }
  .section-title5 {
    text-align: center; /* Centers the text horizontally */
    text-align: center; /* Center the title */
    padding: 0.2rem auto; 
    font-size: 2.5em;
    color: #A3A3A3;
  }
  .submit-btn5{
    margin-top: 3px;
  }
  .program-cards{
    display: flex;
    gap: 1rem;
    padding: 0 0px;
    margin: 0 auto;
  }
  .section-title5{
    color: #101902;
  }
  .program-cards{
    padding: 50px;
    
  }

.submit-btn5:hover{
  color: #000;
}
  /* Program Cards Container */
.program-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0 80px;
  margin: 0 auto;
  padding: 0px 160px;
}

/* Program Card */
.program-card {
  flex: 1 1 calc(33.33% - 1rem); /* Three cards per row */
  padding: 1.3rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s;
  background-color: #526E8A;
  color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}

.program-card:hover {
  /* background-color: #101902; */
  transform: scale(1.05);
  color: #0E2031;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}

.program-card h4,
.program-card p {
  margin: 10px 0;
  font-size: 1rem;
  
}

  .slideshow-image5 {
    max-width: 50%;
    /* margin: 100px 10px; */
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.8);
  }
  .program-name-details{
    font-size: 1.3em;
  }


  .input , textarea {
    width: 90%;
    padding: 1rem;
    border: none;
    border-radius: 10px;
    color: black;
    font-size: 1rem;
    box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.2);
    margin: 1px auto;
    display: block;
    color: #101902;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
  }

  .select{
    width: 130%;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    font-family:poppins;
  }

  .input, select {
    /* width: 90%; */
    padding: 1rem;
    border: none;
    border-radius: 10px;
    font-family:poppins;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3); /* Horizontal, Vertical, Blur Radius, Color */
  }
  input {
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    color: white;
    font-family:poppins;
  }

}




@media screen and (max-width: 768px) {
  .program-section{
    padding: 30px;
  }

  .program-cards{
    display: flex;
    flex-wrap: wrap;
    /* gap: 1rem; */
    /* padding: 0 200px; */
    margin: 0 auto;
  }
  .program-cards {
    padding: 0 20px;
    gap: 0.5rem;
  }
  .program-card1{
    display: flex;
    flex-wrap: wrap;
    /* gap: 1rem; */
    /* padding: 0 200px; */
    margin: 0 auto;
  }
  .program-card1 {
    padding: 0 20px;
    gap: 0.5rem;
  }
  .section-title5 {
    text-align: center;
    margin: auto auto;
    padding: 0.2rem 0;
    font-size: 2em;
    color: white;
  }

  .program-details-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    height: 200px;
  }
  .program-image {
    display: none;
  }
  

  .program-image img {
    width: 100%; /* Ensure images take full width on small screens */
  }

  .program-details {
    width: 100%; /* Adjust for full-width on mobile */
    padding-left: 0;
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 45%;
        max-width: 400px;
        backdrop-filter: blur(10px);
        border-radius: 15px;
        padding: 15px;
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
        color: white;
        margin-top: -50px;
        height: 260px;

        background-color: #fff;  /* White background to contrast the border */
        padding: 20px;
        border-radius: 10px;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);  /* Inward shadow effect */
        border: 2px solid #ccc;  /* Light grey border */
  }
  .programs-title{
    font-size: 2em;
  }
  .slideshow-container5 {
    width: 100%; /* Adjust this value as needed to make it bigger */
    margin: 100px auto; /* This centers the container horizontally */
    position: relative; /* Ensures positioning of slides is relative to the container */
  }
  .program-name-details p{
    font-size: 2em;
  }
  
  .submit-btn5 {
    width: 70%; /* Make the submit button full width on mobile */
    font-size: 2em;
  }
  .enquiry-form-container5 {
    width: 80%; /* Adjust this value to make it smaller or bigger */
    margin: 0 auto; /* Centers the container horizontally */
    box-sizing: border-box; /* Ensures padding doesn't affect the width */
    padding: 0px;
  }
  .enquiry-form5 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #547698;
    padding: 40px;
    height: 360px;
    margin-bottom: 100px;
  }
  input, select, textarea {
    width: 100%; /* Center and keep fields away from container edges */
    padding: 1rem;
    border: none;
    border-radius: 5px; /* Rounded corners for inputs */
    color: white; /* White text color */
    font-size: 2.5rem;
    margin: 1px auto; /* Center the input fields within the container */
    display: block; /* Ensures inputs take full width and stay centered */
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
  }
  
    .programs-title {
      font-size: 20px; /* Larger font size for tablets and up */
    }
  
    .submit-btn5 {
      margin-top: 30px;
      width: 160px; /* Restore button size for larger screens */
    }
    
    select{
      width: 107%; 
      box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    }
    .programs-title2{
      font-size: 2.4em;
    }
    .program-details h3{
      position: center;
      font-size: 3em;
    }
    .program-name-details {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically */
    height: 100%;           /* Set the height if needed */
    text-align: center;     /* Ensures text alignment inside */
  }
  .section-title5{
    color: #0E2031;
    font-size: 3em;
  }
  .program-card h4, .program-card p{
    font-size: 2em;
  }
  .slideshow-image5{
    max-width: 70%;
  }
  .program-image1{
    display: none;
  }
  .input, textarea {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 2.3rem;
    margin: 1px auto;
    display: block;
    font-size: 2.4em;
    font-family: poppins;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
  }
  .select {
    width: 100%;
    padding: 0.2rem;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 2rem;
    margin: 1px auto;
    display: block;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.8);
  }
  .program-details p {
    margin: 10px 0;
    color: rgb(0, 0, 0);
    font-size: 2em;
}
.submit-btn5:hover{
  color: #000;
  color: #000;
}
.program-card {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(119% - 1rem);
  padding: 1.3rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.6);
}
.program-section {
  margin-top: 10px;
  /* background: linear-gradient(to bottom, #a8e06336 10%, #ffffff 60%); */

}
}




@media (max-width: 768px) {
  .slideshow-container5 {
    height: 650px; /* Adjust container height for smaller screens */
  }

  /* Green squares adjustments for mobile */
  .green-square.square-1,
  .green-square.square-2,
  .green-square.square-3,
  .green-square.square-4,
  .green-square.square-5,
  .green-square.square-6,
  .green-square.square-7 {
    width: 100px;
    height: 100px;
  }

  .green-square.square-1 {
    top: 0%;
    left: 30%;
  }

  .green-square.square-2 {
    top: 4%;
    right: 25%;
    width: 50px;
    height: 50px;
  }

  .green-square.square-3 {
    bottom: 10%;
    left: 5%;
    display: none;
  }

  .green-square.square-4 {
    bottom: 7%;
    right: 9%;
    width: 60px;
    height: 60px;
  }

  .green-square.square-5 {
    top: 35%;
    left: 30%;
    width: 80px;
    height: 80px;
  }

  .green-square.square-6 {
    top: 70%;
    right: 20%;
    display: none;
  }

  .green-square.square-7 {
    bottom: 22%;
    left: 30%;
    width: 150px;
    height: 150px;
  }

  /* Center Image */
  .image-box.center {
    width: 180px; /* Adjust width for mobile */
    height: 180px; /* Adjust height for mobile */
    top: 43%;
    left: 63%;
    transform: translate(-50%, -50%);
  }

  /* Top-Left Image */
  .image-box.top-left {
    width: 180px;
    height: 150px;
    top: 5%;
    left: 5%;
  }

  /* Top-Right Image */
  .image-box.top-right {
    width: 100px;
    height: 100px;
    top: 13%;
    right: 21%;
  }

  /* Bottom-Left Image */
  .image-box.bottom-left {
    width: 100px;
    height: 100px;
    bottom: 56%;
    left: 12%;
  }

  /* Bottom-Right Image */
  .image-box.bottom-right {
    width: 100px;
    height: 100px;
    bottom: 11%;
    right: 19%;

  }

  /* Far-Left Image */
  .image-box.far-left {
    width: 200px;
    height: 170px;
    top: 71%;
    left: 3%;
  }

  /* Far-Right Image */
  .image-box.far-right {
    width: 140px;
    height: 100px;
    top: 65%;
    right: 9%;
  }
}


.animated-batch-info {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 600px; /* Adjust based on content */
}

.batch-slide {
  display: flex;
  animation: slide 10s infinite;
}

.batch-info {
  min-width: 90%;
  padding: 10px;
  background-color: #f0f0f0;
  margin: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.batch-info h4 {
  color: #333;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-100%);
  }
  75% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}



