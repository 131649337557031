.main-containerss {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to bottom,#f0f1f3, #b0f63f5e );
  position: relative;  /* Add this to contain the pseudo-element */
  overflow-x: hidden; /* Add this to prevent horizontal scrolling */
}

/* Add this new pseudo-element */
.main-containerss::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('/public/images/bluedotedlines.png');
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  z-index: -1;
  transform: rotate(0deg);
  background-blend-mode: overlay;
  opacity: 0.13;
}
.brouch{
  position: absolute;
  top:0;
  right:-6px;
  width:70px;
  height:80px;
  z-index:100;
}

.content-section {
  margin: 90px 0px;
  padding: 20px 0;
  padding-top: 60px;
}

.section-header {
  width: 75%;
  margin-bottom: 30px;
}

.left-align {
  margin-right: auto;
}

.right-align {
  margin-left: auto;
  text-align: right;
}

.gradient-text {
  font-size: 2.3rem;
  font-weight: bold;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  padding: 0 10px;
  color: white;
  border-radius: 25px;
  
}

.green-blue {
  background: linear-gradient(to right, #A8D85D, #639f02);
  color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 0 15px 5px rgba(243, 249, 244, 0.7);
  padding-left: 20%;
}

.blue-green {
  background: linear-gradient( to right , #3C5C7B, #5988b6);
  color: white;
  border-top-right-radius:0;
  border-bottom-right-radius:0;
  box-shadow: 0 0 15px 5px rgba(77, 192, 231, 0.7);
  padding-right: 20%;
}

.ambassadors-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  gap: 20px;
  margin:5% 2% 10%;
 
}

.achievers-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  justify-items: center;
  
  margin:5% 2% 10%;
  transition: opacity 0.5s ease-in-out;
  animation: heartbeat 1.2s infinite;
}


.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
}

.achiever-card {
  width: 200px;
  padding: 15px;
  background: #efffd45e;
  border-radius: 10px;
  box-shadow: 6px 6px 10px #ffff, -6px -6px 10px #bebebe;
  
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* To position the ripple inside the card */
  overflow: hidden; 
}

/* .achiever-card::before {
  content: '';
  position: absolute;
  top: 10%;
  left: 10%;
  width: 1px;
  height: 1px;
  background-color: #E5FEC0;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  
  transition: transform 0.5s ease, width 0.5s ease, height 0.5s ease;
  z-index: 0; 
}  */

.achiever-card:hover::before {
  transform: translate(-50%, -50%) scale(1); /* Expand the circle */
  width: 200%; /* Increase the size of the circle*/
  height: 200%;  
  box-shadow: 0 0 15px 5px #E5FEC0;
  /* Increase the size of the circle */
}  

.achiever-card:hover {
  transform: translateY(-5px);
  /* box-shadow: 6px 6px 10px #ffff, -6px -6px 10px #bebebe; */
  box-shadow: inset 4px 4px 8px #ffff, inset -4px -4px 8px #bebebe;
  border: 2px solid #E5FEC0;
  transform: scale(1.05);

  /* box-shadow: 0 0 15px 5px #E5FEC0; */
}
.achiever-card .achiever-name, 
.achiever-card .achiever-photo,
.achiever-card .company-name,
.achiever-card .card-company-logo{
    z-index: 1; /* Keep text above the ripple */
    position: relative; /* Ensure text is in the correct stacking context */
}


.placed-card {
  width: 200px;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 
              4px 0 8px rgba(0, 0, 0, 0.2),
              -4px 0 8px rgba(0, 0, 0, 0.2),
              0 -4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* To position the ripple inside the card */
  overflow: hidden; 
  color: black;
}
.placed-card::before {
  content: '';
  position: absolute;
  top: 10%;
  left: 10%;
  width: 1px;
  height: 1px;
  background-color: #5988b6;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s ease, width 0.5s ease, height 0.5s ease;
  z-index: 0; /* Ensure the ripple is behind the text */
}

.placed-card:hover::before {
  transform: translate(-50%, -50%) scale(1); /* Expand the circle */
  width: 200%; /* Increase the size of the circle */
  height: 200%; /* Increase the size of the circle */
}

.placed-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 2px solid #5988b6;
  box-shadow: 0 0 15px 5px #5988b6;
  transform: scale(1.05);
  
}
.placed-card .achiever-name, 
.placed-card .achiever-photo,
.placed-card .company-name,
.placed-card .card-company-logo{
    z-index: 1; /* Keep text above the ripple */
    position: relative; /* Ensure text is in the correct stacking context */
}
.achiever-photo {
  width: auto; /* Adjust width based on height */
  height: 100%; /* Maintain height */
  max-width: 150px; /* Limit the width */
  max-height: 150px; /* Limit the height */
  aspect-ratio: 3.4 / 4; /* Enforce 3:4 aspect ratio */
  border-radius: 18%;
  object-fit: cover;
  margin-bottom: 10px;
}


.achiever-name {
  font-size: 1rem;

  margin: 10px 0;
  text-align: center;
}


.company-name {
  font-size: 0.9rem;
  margin-top: auto;
  font-weight: bolder;
  color:white;
  background-color: #A8D85D;
  border-radius: 25px;
  padding:2% 5%;
}

.card-company-logo {
  width: 60px;
  height: auto;
  margin-top: auto;
}
@media screen and (min-width:1025px ) and (max-width:1250px) {
  .ambassadors-grid {
    grid-template-columns: repeat(4, 1fr);
  }
  
  .achievers-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 1024px) {
  .section-header {
    width: 80%;
  }
  
  .ambassadors-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .achievers-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .achiever-card {
    width: 180px;
  }
}

@media screen and (max-width: 768px) {
  .section-header {
    width: 90%;
  }
  
  .gradient-text {
    font-size: 2rem;
  }

  .ambassadors-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .achievers-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .achiever-card {
    width: 160px;
  }
}

@media screen and (max-width: 480px) {
  .section-header {
    width: 100%;
  }
  .brouch{
    width:50px;
    height:60px;
    right:-3px;
  }
  .gradient-text {
    font-size: 2.0rem;
    text-align: center;
  }

  .ambassadors-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .achievers-grid {
    grid-template-columns: 1fr;
  }

  .achiever-card {
    width: 140px;
  }
  .blue-green{
    padding-right: 0px;
  }
  .green-blue{
    padding-left: 0px;
  }
}