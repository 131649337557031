
.extraFeatures-page-container {
  position: relative;
  z-index: 2;
  background-color: #EEFFD5;
}

.text-greenf{
  font-size: 1.5em;
  font-weight: 400;
  color: #3C5C7B;
  font: poppins;
}

.text-white2f{
  font-size: 3em;
  font-weight: 600;
  color: #C6E894;
  font: poppins;
}
/* Card Styling */
.extraFeatures-cards {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.extraFeatures-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 15px;
}

.extraFeatures-card {
  height: 90px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.extraFeatures-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.extraFeatures-card-content {

  color: rgb(255, 255, 255);
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}

.card-title1{
  font-size: 1em;
}

.extraFeatures-card h2 {
  font-size: 1.5rem;
  margin: 0 0 10px;
}

.extraFeatures-card p {
  font-size: 0.8rem;
  margin: 0;
}


/* Centering the Title */
.extraFeatures-title {
  text-align: center; /* Centers the text horizontally */
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: rgb(255, 255, 255); /* Adjust the color as needed */
  position: relative;
  z-index: 3; /* Ensure it stays above the background animation */
}

/* Additional container adjustments for centering */
.extraFeatures-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Centers text horizontally within content */
  padding: 20px;
  margin-bottom: 30px;
}

.extraFeatures-center-image {
  display: flex;
  justify-content: center; /* Centers the image horizontally */
  align-items: center; /* Centers the image vertically if the container has a height */
}

.extraFeatures-center-image img {
  max-width: 50%; /* Adjust the percentage to make the image smaller or larger */
  height: auto; /* Maintains the aspect ratio */
}






/* Card Styling */
.extraFeatures-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between cards */
  margin: 10px 0;
}

.extraFeatures-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 15px;

}

.extraFeatures-card {
  height: 68px; /* Smaller card size */
  width: 400px; /* Adjust width to make cards smaller */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  overflow: hidden;
  color: #ffffff;
}


.extraFeatures-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.extraFeatures-card-content {
  padding: 12px;
  text-align: center;
  border-radius: 10px;
  color: #ffffff;


  height: 60px; /* Smaller card size */
  width: 400px; /* Adjust width to make cards smaller */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  overflow: hidden;

}

.extraFeatures-card h2 {
  font-size: 1rem;
  margin: 0 0 10px;
  color: #ffffff;
}

.extraFeatures-card p {
  font-size: 0.8rem;
  margin: 0;
  color: #ffffff;
}


/* Centering the Title */
.extraFeatures-title {
  text-align: center; /* Centers the text horizontally */
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 40px;
  position: relative;
  z-index: 3; /* Ensure it stays above the background animation */
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); 
}

/* Additional container adjustments for centering */
.extraFeatures-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Centers text horizontally within content */
  padding: 70px;
  margin-bottom: 100px;
}

.extraFeatures-center-image {
  display: flex;
  justify-content: center; /* Centers the image horizontally */
  align-items: center; /* Centers the image vertically if the container has a height */
  width: 100%; /* Ensures it takes up the full width of its container */
}

.extraFeatures-center-image img {
  max-width: 80%; /* Adjust the percentage to make the image smaller or larger */
  height: auto; /* Maintains the aspect ratio */
}


.extraFeatures-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.extraFeatures-card-content {
  color: #fff;
  padding: 12px;
  text-align: center;
  border-radius: 10px;
  color: #ffffff;
}

.extraFeatures-card h2 {
  font-size: 1rem;
  margin: 0 0 10px;
  color: #3C5C7B;
}

.extraFeatures-card p {
  font-size: 0.8rem;
  margin: 0;
  color: #3C5C7B;
}


/* .extraFeatures-column.left .extraFeatures-card {
  transform-origin: right center;
}

.extraFeatures-column.right .extraFeatures-card {
  transform-origin: left center;
} */



.extraFeatures-card:nth-child(1) {
  background-color: #E1FFB4; /* Soft red */
}

.extraFeatures-card:nth-child(2) {
  background-color: #d5fc9a; /* Purple */
}

.extraFeatures-card:nth-child(3) {
  background-color: #c8fb7b; /* Green */
}

.extraFeatures-card:nth-child(4) {
  background-color: #c2fb6c; /* Pink */
}

.extraFeatures-card:nth-child(5) {
  background-color: #d0fc71; /* Blue */
}


.highlight-feature {
  color: #3C5C7B; /* White color for the word Feature */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); 
}

.extraFeatures-title{
  font-size: 3em;
  margin-top: 60px;

}


@media only screen and (max-width: 768px) {
  /* Adjusting the title */
  .extraFeatures-title {
    font-size: 3.5rem; /* Increase title size */
    margin-bottom: 50px;
  }

  /* Adjusting the content container */
  .extraFeatures-content {
    padding: 10px; /* Slightly reduce padding */
    margin-bottom: 200px;
    margin-top: 60px;
  }

  /* Center image adjustments */
  .extraFeatures-center-image {
    width: 80%; /* Reduce the width */
  }

  /* Adjusting card layout */
  .extraFeatures-cards {
    flex-direction: column; /* Stack cards vertically */
    gap: 6px; /* Smaller gap between cards */
    margin: 0px 0;
    width: 90%;
  }

  .extraFeatures-card {
    height: auto; /* Allow cards to resize dynamically */
    width: 100%; /* Full-width cards */
    padding: 1px; /* Reduce padding */
  }

  .extraFeatures-card-content {
    height: auto; /* Adjust height for content */
    width: 90%;
    padding: 30px; /* Reduce padding */
  }

  .extraFeatures-card h2 {
    font-size: 2.5em;
  }

  .extraFeatures-card {
    transform: rotate(0deg) translate(0px, 0px) !important; /* Reset rotation and translation */
  }

  .extraFeatures-cards {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center align cards */
  }

  .extraFeatures-card-content {
    padding: 18px; /* Further adjust padding for smaller screens */
  }

  .extraFeatures-center-image img {
    width: 0%; /* Further reduce center image width */
  }
  .extraFeatures-column{
    gap:8px;
  }
}



@media only screen and (max-width: 1024px) and (min-width: 768px)  {
  /* Adjust the cards layout to have two rows of four cards each */
  .extraFeatures-cards {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center cards */
    gap: 10px; /* Space between rows */
    margin-left: -1px;
    font-size: 3em;
  }

  .extraFeatures-column {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px; /* Gap between the cards in a row */
    width: 100%;
    flex-wrap: wrap; /* Ensures the cards wrap onto the next row */
    padding: 0 10px;
    
  }

  .extraFeatures-card {
    height: 90px; /* Adjust card size for tablet */
    width: 300px; /* Adjust width for tablet */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
    overflow: hidden;
    color: #ffffff;
    
  }

  .extraFeatures-title {
    font-size: 3rem;
    margin-bottom: 30px;
    color: #3C5C7B;
  }

  .extraFeatures-content {
    padding: 30px;
    margin-bottom: 150px;
   
  }

  .extraFeatures-center-image img {
    max-width: 30%;
  }

  .extraFeatures-card-content {
    font-size: 0.5rem; 
  }

  .extraFeatures-card {
    transform: rotate(0deg) translate(0px, 0px) !important; /* Reset rotation and translation */
  }

  .extraFeatures-card h2 {
    font-size: 2.2em;
  }

}
