@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body, .set-font {
  font-family: 'Poppins', sans-serif;
}

.navigation {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0E2031;
  padding: 10px 20px;
  height: 80px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 20px); /* Adjust to prevent overflow */
  z-index: 1000;
  margin-right: auto; /* Prevent overlapping at the right end */
}

.logo img {
  height: 100px;
  margin-right: 40px;
}

.nav-links {
  list-style-type: none;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin: 0 2%;
  padding: 0;
}

.nav-links li {
  position: relative;
  margin-left: 50px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  padding: 12px 20px;
  line-height: 68px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-links a:hover {
  background-color: #CFFB8D;
  color: #a092bc;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  text-decoration: none;
  color: white;
  font-weight: 500;
  padding: 1px 10px;
  line-height: 68px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1em;
}

.dropdown-toggle:hover {
  background-color: #CFFB8D;
  color: #150f21;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #0E2031;
  z-index: 1000;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  min-width: 250px;
  margin-left: -100px;
  list-style-type: none;
  padding: 0;
}

.dropdown-content li {
  margin: 0;
}

.dropdown-content a {
  display: block;
  line-height: 1.5;
  padding: 15px 20px;
}

.dropdown-content a:hover {
  background-color: #CFFB8D;
  color: #ffffff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.mobile-menu-toggle {
  display: none;
  font-size: 24px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-right: 10px; /* Adds space from the right edge */
}

@media screen and (max-width: 1200px) {
  .mobile-menu-toggle {
    display: block;
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 80px;
    right: 0;
    background-color: #0E2031;
    z-index: 999;
    width: auto;
    min-width: 250px;
    border-radius: 0 0 5px 5px;
    box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    margin: 0;
  }

  .nav-links.open {
    display: block;
  }

  .nav-links li {
    margin: 0;
    width: 100%;
  }

  .nav-links a {
    display: block;
    padding: 12px 25px;
    line-height: 1.5;
    white-space: nowrap;
    border-radius: 0;
  }

  .dropdown {
    width: 100%;
  }

  .dropdown-toggle {
    width: 100%;
    text-align: left;
    line-height: 1.5;
    padding: 12px 25px;
    display: block;
    border-radius: 0;
  }

  .dropdown-content {
    position: static;
    box-shadow: none;
    margin-left: 0;
    min-width: 100%;
    border-radius: 0;
    background-color: #0a1825;
  }

  .dropdown-content a {
    padding-left: 45px;
  }

  .nav-links li:hover,
  .dropdown-toggle:hover {
    background-color: rgba(207, 251, 141, 0.1);
  }

  .nav-links a:hover,
  .dropdown-content a:hover {
    background-color: rgba(207, 251, 141, 0.2);
  }

  .dropdown:hover .dropdown-content {
    display: none;
  }

  .dropdown.active .dropdown-content {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .logo img {
    height: 80px;
    margin-right: 20px;
  }

  .nav-links {
    min-width: 200px;
  }
}
