.page-containerforschool {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.background-sectionforschool {
  min-height: 100vh;
  width: 100%;
  background: url('/public/images/SchoolProgram.jpeg') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

.background-sectionforschool::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, 
    rgba(198, 232, 148, 0.7),  
    rgba(255, 255, 255, 0.7),  
    rgba(153, 192, 95, 0.7)    
  );
}

.content-wrapperforschool {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.main-titleforschool {
  color: black;
  font-size: clamp(2rem, 5vw, 4rem);
  font-weight: 600;
  text-align: center;
  z-index: 1;
  padding-top: 5%;
}

.projects-gridforschool {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  z-index: 1;
}

.project-cardforschool {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-cardforschool:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
}

.card-image-containerforschool {
  position: relative;
  padding-top: 70%;
  overflow: hidden;
}

.card-imageforschool {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-cardforschool:hover .card-imageforschool {
  transform: scale(1.05);
}

.card-contentforschool {
  padding: 1rem;
  text-align: center;
}

.card-titleforschool {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

/* Responsive Design */
@media (min-width: 768px) {
  .projects-gridforschool {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 15%;
  }
}

@media (min-width: 1024px) {
  .projects-gridforschool {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .content-wrapperforschool {
    padding: 6rem 5rem;
  }
}

/* Mobile Optimization */
@media (max-width: 767px) {
  .content-wrapperforschool {
    padding: 13rem 1.5rem;
    gap: 3rem;
  }
  
  .card-titleforschool {
    font-size: 1.125rem;
  }
}

/* Landscape Mode */
@media (max-height: 700px) and (orientation: landscape) {
  .content-wrapperforschool {
    padding: 2rem 1rem;
    gap: 2rem;
  }
  
  .projects-gridforschool {
    gap: 1rem;
  }
}
/* Make sure the page container has relative positioning */
.page-containerforschool {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
