
.navbar1 {
    background-color: #C6E894;
    color: white;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(68, 15, 15, 0.1);
}
  
.title-container {
    margin-bottom: 15px;
}
  
.title-container h1 {
    margin: 0;
    font-size: 36px;
    font-weight: bold;
    font-family: 'Bangers', sans-serif;
    color: #3C5C7B;
    margin-top:6rem;
}
.title-container h1:nth-child(2) {
    margin-top: 0.5rem;
    font-size: 32px;
}
.title-container .tagline {
    font-size: 26px;
    font-weight: 300;
    margin: 5px 0 0;
    color: #3C5C7B;
    font-family: 'Poppins', sans-serif;
}
  
.search-container {
    display: flex;
    justify-content: center;
}
  
.search-bar {
    padding: 12px 20px;
    font-size: 16px;
    border: 2px solid #ffffff;
    border-radius: 25px;
    outline: none;
    width: 350px;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
    color: #3C5C7B;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
.search-bar::placeholder {
    color: #3C5C7B;
}
  
.search-bar:focus {
    border: 2px solid #b7d688;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.no-results {
    color: #ffffff;
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px; 
}

.notes-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 4fr)); 
    padding: 20px;
    margin: 0 10%;
    justify-items: center;
    gap: 30px;
}
  

.note-item {
    background-color: #ffffff;
    color: #E5FEC0;
    padding: 20px;
    border-radius: 85px 0px 15px 0px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .6);
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s, border 0.3s, background-color 0.3s;
    text-align: center;
    border: 2px solid transparent;
    width: 190px; 
    height: 180px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden; 
}
  

.note-item::before {
    content: '';
    position: absolute;
    top: 10%;
    left: 10%;
    width: 1px;
    height: 1px;
    background-color: #E5FEC0;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.5s ease, width 0.5s ease, height 0.5s ease;
    z-index: 0; 
}
  
/* .note-item:hover::before {
    transform: translate(-50%, -50%) scale(1); 
    width: 200%; 
    height: 200%;
}
  
.note-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border: 2px solid #E5FEC0;
    box-shadow: 0 0 15px 5px #E5FEC0;
} */

.note-item .category-title, 
.note-item .category-description {
    z-index: 1; 
    position: relative; 
}

.category-title {
    font-size: 22px;
    font-weight: bold;
    color: #3C5C7B;
    margin-bottom: 10px; 
}

.category-description {
    font-size: 14px;
    font-weight: 300; 
    color: #3C5C7B;
    margin-top: 0;
}

.category-logo {
    width: 60px;
    height: 60px;
    margin-top: 10px;
    margin-bottom: 6px;
    object-fit: contain;
}
  

@media (max-width: 768px) {
    .search-container {
        display: flex;
        justify-content: center;
        padding: 10px;
        width: 440px;
        border-radius: 800px;
    }
  
    .search-bar {
        padding: 10px 15px; 
        font-size: 14px;
        width: 80%; 
        border-radius: 20px; 
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    }
  
    .search-bar::placeholder {
        font-size: 14px; 
    }
  
    .search-bar:focus {
        border: 2px solid #b7d688; 
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15); 
    }
    .notes-list {
        grid-template-columns: repeat(3, 1fr); 
        padding: 10px;
        margin: 10% 15%;
    }
    .search-bar {
        padding: 10px 18px;
        font-size: 12px;
        border: 2px solid #ffffff;
        border-radius: 100px;
        outline: none;
        width: 300px;
        transition: all 0.3s ease-in-out;
        background-color: #ffffff;
        color: #3C5C7B;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
      
    .search-bar::placeholder {
        color: #3C5C7B;
    }

    .note-item {
        background-color: #ffffff;
        color: #E5FEC0;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        transition: transform 0.3s, box-shadow 0.3s, border 0.3s, background-color 0.3s;
        text-align: center;
        border: 2px solid transparent;
        width: 250px; /* Fixed width */
        height: 150px; /* Fixed height to ensure all cards are the same size */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative; /* To position the ripple inside the card */
        overflow: hidden; /* To ensure the ripple stays within the card */
    }
    .title-container h1 {
        margin-top:6rem;
        font-size: 45px;
        font-weight: bold;
        font-family: 'Bangers', sans-serif;
        color: #3C5C7B;
    }
    .title-container .tagline {
        font-size: 16px;
        font-weight: 300;
        margin: 5px 0 0;
        color: #3C5C7B;
        font-family: 'Poppins', sans-serif;
    }
    .notes-list {
        grid-template-columns: repeat(3, 1fr); /* Ensures 3 tiles in a row */
        padding: 0;
        margin: 0 8px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .note-item {
        width: 100px; 
        height: 120px; 
        font-size: 14px; 
        padding: 10px;
    }

    .note-item .category-title {
        font-size: 16px;
    }

    .note-item .category-description {
        font-size: 12px;
    }

    .category-logo {
        width: 60px;
        height: 60px;
        margin-bottom: -24px;
        object-fit: contain;
    }
      
}

@media (max-width: 480px) {
    .search-bar {
        font-size: 13px;
        border: 2px solid #ffffff;
        border-radius: 35px;
        outline: none;
        width: 250px;
        width: 85%;
        transition: all 0.3s ease-in-out;
        background-color: #ffffff;
        color: #3C5C7B;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
    }
    .search-bar {
        width: 85%;
        max-width: 250px;
       
    }
    .search-bar::placeholder {
            font-size: 14px;
        color: #3C5C7B;
    }
    .title-container h1 {
        margin: 0;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Bangers', sans-serif;
        color: #3C5C7B;
        margin-top:12rem;
    }
    .title-container h1:nth-child(2) {
        margin-top: 0.5rem;
        font-size: 32px;
    }
    .title-container .tagline {
        font-size: 16px;
        font-weight: 300;
        margin: 5px 0 0;
        color: #3C5C7B;
        font-family: 'Poppins', sans-serif;
    }
    .notes-list {
        grid-template-columns: repeat(2, 1fr); 
        gap: 15px;
    }

    .note-item {
        width: 130px;
        height: 150px; 
    }

    .category-logo {
        width: 60px;
        height: 60px;
        margin-bottom: -22px;
        object-fit: contain;
    }
      
}
@media screen and (min-width: 481px) and (max-width: 768px) {
    .search-bar {
        padding: 12px 20px;
        font-size: 14px;
        border: 2px solid #ffffff;
        border-radius: 25px;
        outline: none;
        width: 300px; 
        transition: all 0.3s ease-in-out;
        background-color: #ffffff;
        color: #3C5C7B;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    .search-bar::placeholder {
        color: #3C5C7B;
        font-size: 14px;
    }
    
    .search-bar:focus {
        border: 2px solid #b7d688;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }

    .title-container h1 {
        margin: 0;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Bangers', sans-serif;
        color: #3C5C7B;
        margin-top: 9rem; /* Adjusted between mobile (12rem) and tablet (7rem) */
    }

    .title-container h1:nth-child(2) {
        margin-top: 0.5rem;
        font-size: 32px;
    }

    .title-container .tagline {
        font-size: 18px;
        font-weight: 300;
        margin: 5px 0 0;
        color: #3C5C7B;
        font-family: 'Poppins', sans-serif;
    }

    .notes-list {
        grid-template-columns: repeat(2, 1fr); 
        gap: 18px; 
        padding: 15px;
        margin: 0 4%;
    }

    .note-item {
        width: 180px;
        height: 170px; 
        font-size: 15px;
        padding: 15px;
    }

    .note-item .category-title {
        font-size: 17px; 
    }

    .note-item .category-description {
        font-size: 14px; 
    }
    .category-logo {
        width: 60px;
        height: 60px;
        margin-bottom: -22px;
        object-fit: contain;
    }
      
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

    .search-bar {
        padding: 12px 20px;
        font-size: 14px;
        border: 2px solid #ffffff;
        border-radius: 25px;
        outline: none;
        width: 350px;
        transition: all 0.3s ease-in-out;
        background-color: #ffffff;
        color: #3C5C7B;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
      
    .search-bar::placeholder {
        color: #3C5C7B;
    }
      
    .search-bar:focus {
        border: 2px solid #b7d688;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
    
    .title-container h1 {
        margin: 0;
        font-size: 35px;
        font-weight: bold;
        font-family: 'Bangers', sans-serif;
        color: #3C5C7B;
        margin-top:7rem;
    }
    .title-container h1:nth-child(2) {
        margin-top: 0.5rem;
        font-size: 32px;
    }
    .title-container .tagline {
        font-size: 20px;
        font-weight: 300;
        margin: 5px 0 0;
        color: #3C5C7B;
        font-family: 'Poppins', sans-serif;
    }
    .notes-list {
        grid-template-columns: repeat(3, 1fr); 
        gap: 20px; /* Consistent spacing between tiles */
        padding: 15px; /* Reduced padding for better fit */
        margin: 0 5%; 
    }

    .note-item {
        width: 180px; /* Slightly reduced width for better spacing */
        height: 180px; /* Adjusted height for proportions */
        font-size: 16px; /* Reduced font size */
        padding: 15px; /* Maintain spacing within the card */
    }

    .note-item .category-title {
        font-size: 18px; /* Slightly smaller title font */
    }

    .note-item .category-description {
        font-size: 14px; /* Adjusted description font size */
    }
}
.search-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
}

.search-bar {
    padding: 12px 20px;
    font-size: 16px;
    border: 2px solid #ffffff;
    border-radius: 25px;
    outline: none;
    width: 350px;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
    color: #3C5C7B;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-bar::placeholder {
    color: #3C5C7B;
}

.search-bar:focus {
    border: 2px solid #b7d688;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 360px) {
    .search-container {
        padding: 6px;
    }
    .search-bar {
        width: 85%;
        max-width: 260px;
        padding: 8px 12px;
        font-size: 13px;
        border-radius: 18px;
    }

    .search-bar::placeholder {
        font-size: 13px;
    }

    .category-logo {
        width: 31px;
        height: 30;
        margin-bottom: -23px;

    }

    .category-logo {
        width: 60px;
        height: 60px;
        margin-bottom: -22px;
        object-fit: contain;
    }
      
      
}




 /* Notes Container */
.notes-container {
background-color: #f0f1f3; /* Soft background */

}
  
  
  /* Individual Card - Neumorphic Design */
  .note-item {
    background: #f0f1f3;
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    box-shadow: 6px 6px 10px #bebebe, -6px -6px 10px #ffffff;
    transition: all 0.3s ease-in-out;
  }
  
  /* Hover Effect - Push Down */
  .note-item:hover {
    box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff;
    transform: scale(0.98);
  }
  
  /* Category Titles */
  .category-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }
  
  /* Category Description */
  .category-description {
    font-size: 18px;
    color: rgb(11, 11, 11);
  }
  
  /* No Results Styling */
  .no-results {
    font-size: 18px;
    color: #888;
    text-align: center;
    padding: 20px;
  }
  







