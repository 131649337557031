/* shared-styles.css */
.shared {
    /* background: linear-gradient(90deg, #C6E894, #ffffff, #99C05F); */
    
    position: relative;
    z-index: 1;
}

.shared::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url('/public/images/bluedotedlines.png'),linear-gradient(180deg, #88C057, #ffffff);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.25;
    z-index: -1;
    pointer-events: none;
}

.legal-page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: system-ui, -apple-system, sans-serif;
    color: #333;
    line-height: 1.6;
    position: relative;
    background: transparent;
    z-index: 2;
}
  .back-buttons {
    position: absolute;
    left: 1rem;
    top: 1rem;
    background-color: #88C057 !important;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    transition: transform 0.2s ease;
    z-index: 4;
  }
  
  .back-button:hover {
    transform: translateX(-3px);
  }
  
  .legal-page-container h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #1a1a1a;
    text-align: center;
    padding-top: 2rem;
  }
  
  .legal-page-container h2 {
    font-size: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #2a2a2a;
  }
  
  .legal-page-container h3 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    color: #3a3a3a;
  }
  
  .legal-page-container h4 {
    font-size: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
    color: #4a4a4a;
  }
  
  .legal-page-container p {
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }
  
  .legal-page-container ul {
    margin-bottom: 1.5rem;
    padding-left: 2rem;
  }
  
  .legal-page-container li {
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
  }
  
  .legal-page-container strong {
    color: #1a1a1a;
  }

  @media (max-width: 768px) {
    .legal-page-container {
      padding: 1rem;
    }
  
    .legal-page-container h1 {
      font-size: 2rem;
    }
  
    .legal-page-container h2 {
      font-size: 1.5rem;
    }
  
    .legal-page-container h3 {
      font-size: 1.25rem;
    }
    .legal-page-container p {
      font-size: 1.15rem;
    }
    .legal-page-container li {
      font-size: 1.15rem;
    }
    .legal-page-container h4 {
      font-size: 1.1rem;
    }
  }
  @media (max-width: 480px) {
    .legal-page-container {
      padding: 1rem;
    }
  
    .legal-page-container h1 {
      font-size: 2rem;
    }
  
    .legal-page-container h2 {
      font-size: 1.75rem;
    }
  
    .legal-page-container h3 {
      font-size: 1.65rem;
    }
    .legal-page-container p {
      font-size: 1.5rem;
    }
    .legal-page-container li {
      font-size: 1.5rem;
    }
    .legal-page-container h4 {
      font-size: 1.1rem;
    }
  }