/* Complete Resumebuilding.css with responsive styles */

.resume-container {
  font-family: Poppins, sans-serif;
  max-width: 100vw;
  margin: 0 auto;
  border-radius: 10px;
  position: relative;
  padding: 3%;
  min-height: 100vh;
  /* background: linear-gradient(90deg, #8fc7ff, #ffffff, #3C5C7B); */
}

.resume-container::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, #93bd50d3, rgba(255, 255, 255, 0.51));
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  opacity: 1; /* Reduced opacity for better contrast */
  z-index: 1;
  pointer-events: none;
}

.resume-container > * {
  position: relative;
  z-index: 2;
}

.back-button1 {
  position: fixed;
  top: 20px;
  left: 20px;
  background-color: #88C057;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  z-index: 200;
}

.back-button1:hover {
  background-color: #9bdc63;
}

.resume-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.main-heading {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  margin-top: 90px;
  margin-bottom: 90px;
  color: #333;
}



.resume-item {
  margin-left: 250px;
  display: flex;
  align-items: start;
  margin-bottom: 10px;
}

.resume-heading {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 2px;
  background-color: #A8D85D;
  padding: 5px 10px;
  border-radius: 5px;
}

.resume-description {
  font-size: 17px;
  color: #666;
  line-height: 1;
  font-weight: bold;
}

/* Updated Slideshow Styles */
.slideshow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 20px 0;
  gap: 20px;
}

.slideshow-images {
  display: flex;
  gap: 20px;
  justify-content: center;
  width: 80%;
}

.slideshow-image {
  width: calc(50% - 10px);
  height: 300px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(74, 68, 69, 0.7),
              0 0 30px rgba(255, 255, 255, 0.5);
  border: .75rem solid white;
  object-fit: cover;
}

.arrow-button {
  background: none;
  border: none;
  color: #333;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;
}

.arrow-button:hover {
  color: #88C057;
}

.arrow-button:focus {
  outline: none;
}




/* Resume Grid */
.resume-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin: 40px auto;

}

/* Resume Cards */
/* Resume Card */
.resume-card {
  background: rgba(68, 224, 25, 0.15);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(68, 224, 25, 0.5);
  text-align: center;
  transition: all 0.3s ease-in-out;
  backdrop-filter: blur(10px);
  border: 2px solid transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

/* Adding a glowing animated border */
.resume-card::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg,#fff, #F2FFDE, #b4d582, #3C5C7B );
  background-size: 400% 400%;
  border-radius: 15px;
  z-index: -1;
  animation: glowing-border 10s linear infinite;
}

/* Glow effect on hover */
.resume-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 20px rgba(32, 32, 32, 0.2);
}

/* Keyframes for the animated glowing border */
@keyframes glowing-border {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}


.resume-card h2 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #302f37;
}

.resume-card p {
  font-size: 14px;
  line-height: 1.6;
}

.resume-card:hover {
  transform: scale(1.05);
  background: rgba(255, 255, 255, 0.3);
}





/* Slideshow */
.slideshow-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  border: 2px solid #ffffff;
}


.slideshow-item {
  margin-top: 5px;
  min-width: 40%;
  transition: opacity 0.5s ease-in-out;
}

.slideshow-item img {
  width: 100%;
  height: auto;
  border-radius: 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


/* Infinite Gallery Animation */
.slideshow-images {
  display: flex;
  flex-wrap: nowrap;
  animation: scrollInfinite 8s linear infinite;
  
}

@keyframes scrollInfinite {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .resume-grid {
    grid-template-columns: 1fr;
  }
  .slideshow-item img {
    height: 250px;
    width: auto;
  }
}





/* Responsive styles for 1024px */
@media screen and (max-width: 1024px) {
  .resume-container {
    padding: 20px;
  }

  .resume-item {
    margin-left: 200px;
  }

  .resume-title {
    font-size: 22px;
  }

  .resume-heading {
    font-size: 17px;
  }

  .resume-description {
    font-size: 15px;
  }

  .slideshow-images {
    width: 90%;
  }

  .slideshow-image {
    height: 250px;
  }
}

/* Responsive styles for 768px */
@media screen and (max-width: 768px) {

  .resume-item {
    flex-direction: column;
    align-items: flex-start;
    margin-left: -1px;
  }

  .resume-title {
    font-size: 20px;
  }

  .resume-heading {
    font-size: 16px;
  }

  .resume-description {
    font-size: 14px;
  }

  .slideshow-images {
    width: 95%;
  }

  .slideshow-image {
    height: 200px;
  }


  
  .resume-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .resume-heading {
    font-size: 2rem; /* Increased heading size */
    font-weight: bold;
  }
  
  .resume-description {
    font-size: 1.7rem; /* Increased description size */
    margin-bottom: 10px;
  }
  
  .slideshow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  
  .arrow-button {
    font-size: 2.5rem; /* Increased arrow button size */
    background: transparent;
    border: none;
    cursor: pointer;
    color: #333;
  }
  
  .slideshow-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slideshow-image {
    width: 100%;
    max-width: 600px; /* Set max width for images */
    margin: 0 10px;
    border-radius: 10px;
  }
  
  .slideshow-item {
    min-width: 100%;
    transition: opacity 0.5s ease-in-out;
  }
}

/* Responsive styles for 480px */
/* @media screen and (max-width: 480px) {
  .resume-container {
    padding: 10px;
  }

  .resume-item {
    margin-left: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .icon {
    font-size: 20px;
    margin-top: 10px;
  }

  .resume-title {
    font-size: 18px;
  }

  .resume-heading {
    font-size: 14px;
  }

  .resume-description {
    font-size: 12px;
  }

  .slideshow-images {
    width: 100%;
  }

  .slideshow-image {
    width: 100%;
    height: 200px;
  }

  .arrow-button {
    font-size: 1.5rem;
  }
} */

.resume-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.resume-grid-container {
  width: 50%;
  /* max-width: 1200px; */
  margin: 2rem auto;
  padding: 0 1rem;
}

.resume-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  width: 100%;
}

.resume-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  position: relative;
}


.label {
  background: #98d660;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  display: inline-block;
  margin-left: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.content {
  margin-left: 2.5rem;
  color: #666;
}

.content p {
  margin: 0.5rem 0;
  line-height: 1.5;
}


@media screen and (max-width: 768px) {
  .resume-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .resume-grid-container {
    padding: 0 0.5rem;
  }

  .resume-card h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #302f37;
  }
  
  .resume-card p {
    font-size: 12px;
    line-height: 1.6;
  }
  
}





