.review-section {
    text-align: center;
    padding: 10px 0;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    font-family: poppins;

    margin-top: 46.25px; /* 90px * 0.625 */
   }
   
   .review-section::before {

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Puts the overlay behind the content */

}
.highlight {
    z-index: -1;
   }
   .review-title {
    font-size: 3.3rem; /* 4.5rem * 0.625 */
    margin-bottom: 46.25px; /* 90px * 0.625 */
   }
   .highlight {
    color: #A8D85D;
    font-weight: bold;
   }
   
   .review-card {
    color: #A8D85D;
    font-weight: bold;
}
.review-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
}

.review-arrow {
    background: none;
    border: none;
    color: #FF7895;
    cursor: pointer;
    padding: 0.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: color 0.3s ease;
    z-index: 2;
}

.review-arrow:hover {
    color: #A8D85D;
}

.review-arrow.prev {
    left: -50px;
}

.review-arrow.next {
    right: -50px;
}

.review-card {

    background-color: #fff;
    border: 2px solid #FF7895;
    border-radius: 10px;
    padding: 40px;
    width: 500px;
    height: 300px;
    margin: 0 auto;
    position: relative;

    box-shadow: 0 0 15px rgba(0, 0, 0, 1);

    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    overflow: hidden;


    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: box-shadow 0.3s ease;

}

.review-card:hover {
    box-shadow: 0 0 20px rgba(255, 120, 149, 0.7),
                0 0 30px rgba(255, 120, 149, 0.5);
}

.quote-icon {

    position: absolute;
    top: 20px;
    left: 20px;
    width: 100px;
    height: 100px;
    background-color: #FF7895;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Playfair Display', serif;
    z-index: 2;

}

.double-quote {
    font-size: 6rem;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2),
                 0 0 5px rgba(255, 255, 255, 0.7);
    line-height: 1;
    margin-top: 5px;
    margin-left: 2px;

}

.stars {
    color: #f1c40f;
    margin-bottom: 10px;
}

.review-author {

    font-weight: bold;
    font-size: 1.1875rem; /* 1.9rem * 0.625 */
    margin-bottom: 10px;

   }
   
   .review-text {
    font-size: 0.9375rem; /* 1.5rem * 0.625 */

    line-height: 1.5;
    color: #555;
    margin-top: 10px;
    overflow: auto;

   }
   
   .review-dots {
    margin-top: 20px;
   }
   
   .dot,
   .active-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    transition: background-color 0.3s;
   }
   
   .active-dot {
    background-color: #FF7895;
   }
   
   .dot:hover {
    background-color: #FF7895;
   }
@media screen and (max-width: 480px) {
    .review-section {
        margin-top: 32px;
        padding: 10px;
    }
    .review-title {
      font-size: 2rem; /* 2.5rem * 0.625 */
      margin-bottom: 31.25px; /* 50px * 0.625 */
    }
    .review-card {
        width: 80%; /* Adjusted width for smaller screens */
        height: auto; /* Allow dynamic height */
        padding: 20px; /* Reduced padding */
        margin: 20px auto; /* Add spacing between cards */
      width: 90%;
      height: auto;
      padding: 20px;
      margin: 20px auto;
    }
    .quote-icon {
      top: 10px;
      left: 10px;
      width: 80px;
      height: 80px;
    }
    
    .double-quote {
      font-size: 3.125rem; /* 5rem * 0.625 */
    }
    
    .stars {
      font-size: 0.6875rem; /* 1.1rem * 0.625 */
      margin-bottom: 5px;
      transform: scale(1.1);
    }
    .review-text {

      font-size: 0.875rem; /* 1.4rem * 0.625 */
      margin-top: 1.5rem;

    }
    
    .review-dots {
      margin-top: 15px;
    }
    
    .dot,
    .active-dot {
      width: 8px;
      height: 8px;
      margin: 0 4px;

        font-size: 2.5rem;
        margin-bottom: 30px;
    }

    .review-container {
        width: 90%;
        margin: -3rem auto;
    }

    .review-card {
        width: 100%;
        height: auto;
        padding: 20px;
    }

    .review-arrow.prev {
        left: -25px;
    }

    .review-arrow.next {
        right: -25px;
    }

    .quote-icon {
        width: 80px;
        height: 80px;
    }

    .double-quote {
        font-size: 5rem;
    }

    .review-author {
        font-size: 1.6rem;
    }

    .review-text {
        font-size: .875rem;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .review-section {
        margin-top: 40px;
        padding: 15px 0;
    }

    .review-title {
        font-size: 2.2rem;
        margin-bottom: 40px;
    }

    .review-container {
        width: 95%;
        max-width: 650px;
    }

    .review-card {
        width: 80%;
        height: auto;
        min-height: 250px;
        padding: 30px;
    }

    .review-arrow.prev {
        left: -25px;
    }

    .review-arrow.next {
        right: -25px;
    }

    .quote-icon {
        width: 90px;
        height: 90px;
        top: 15px;
        left: 15px;
    }

    .double-quote {
        font-size: 4.5rem;
    }

    .review-author {
        font-size: 1.1rem;
    }

    .review-text {
        font-size: 0.9rem;
        margin-top: 1.25rem;
    }

    .stars {
        font-size: 0.8rem;
        transform: scale(1.05);
    }
}
@media screen and (max-width: 360px) {
    .review-section {
        margin-top: 20px;
        padding: 10px;

    }
    
    .review-title {
      font-size: 2rem; /* 2.5rem * 0.625 */
      margin-bottom:20px; /* 50px * 0.625 */

    }
    
    .review-card {
        width: 80%; /* Adjusted width for smaller screens */
        height: auto; /* Allow dynamic height */
        padding: 20px; /* Reduced padding */
        margin: 20px auto; /* Add spacing between cards */

      width: 90%;
      height: auto;
      padding: 20px;
      margin: 20px auto;

    }
    
    .quote-icon {
      top: 10px;
      left: 10px;
      width: 80px;
      height: 80px;
    }
    
    .double-quote {
      font-size: 3.125rem; /* 5rem * 0.625 */
    }
    
    .stars {
      font-size: 0.6875rem; /* 1.1rem * 0.625 */
      margin-bottom: 5px;
      transform: scale(1.1);
    }
    .review-text {
      font-size: 0.875rem; /* 1.4rem * 0.625 */
      margin-top: 1.5rem;

    }
    
    .review-dots {
      margin-top: 15px;
    }
    
    .dot,
    .active-dot {
      width: 8px;
      height: 8px;
      margin: 0 4px;

        font-size: 2.5rem;
        margin-bottom: 30px;
    }

    .review-container {
        width: 90%;
    }

    .review-card {
        width: 100%;
        height: auto;
        padding: 20px;
    }

    .review-arrow.prev {
        left: -25px;
    }

    .review-arrow.next {
        right: -25px;
    }

    .quote-icon {
        width: 80px;
        height: 80px;
    }

    .double-quote {
        font-size: 5rem;
    }

    .review-author {
        font-size: 1.6rem;
    }

    .review-text {
        font-size: .875rem;
    }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
    .review-section {
        margin-top: 40px;
        padding: 15px 0;
    }

    .review-title {
        font-size: 2.5rem;
        margin-bottom: 40px;
    }

    .review-container {
        width: 95%;
        max-width: 650px;
    }

    .review-card {
        width: 80%;
        height: auto;
        min-height: 250px;
        padding: 30px;
    }

    .review-arrow.prev {
        left: -25px;
    }

    .review-arrow.next {
        right: -25px;
    }

    .quote-icon {
        width: 90px;
        height: 90px;
        top: 15px;
        left: 15px;
    }

    .double-quote {
        font-size: 4.5rem;
    }

    .review-author {
        font-size: 1.1rem;
    }

    .review-text {
        font-size: 0.9rem;
        margin-top: 1.25rem;
    }

    .stars {
        font-size: 0.8rem;
        transform: scale(1.05);
    }
}
