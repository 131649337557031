/* Updated Internship.css with enhanced visibility and contrast */

.internship-container1 {
  font-family: Poppins, sans-serif;
  /* background: linear-gradient(90deg, #8fc7ff, #ffffff, #3C5C7B); */
  max-width: 100vw;
  max-height: auto;
  border-radius: 10px;
  position: relative;
  height: auto;
  padding: 20px;
  z-index: 1;
  min-height: 100vh;
}
.highlight-title{
  color: #74a944;
}

.internship-container1::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url('/public/images/bluedotedlines.png'), linear-gradient(135deg, #88C057, #ffffff);;
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  z-index: -1; /* Moved background behind the content */
  transform: rotate(0deg);
  background-blend-mode: overlay;
  opacity: 0.35;
   /* Reduced opacity for better contrast */
  
}



.internship-title {
  font-size: 2.5rem;
  color: #222; /* Darker text color */
  text-align: center;
  margin-bottom: 3%;
  opacity: 1;
}

.section-title {
  font-size: 2rem;
  color: #333; /* Increased contrast */
  margin: 2% auto;
  text-align: center;
  opacity: 1;
}

.eligibility-section {
  margin-bottom: 40px;
}

.resume-heading3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px; /* Space below the card */
  background-color: #99C05F; /* Background color */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Reduced padding */
  border-radius: 8px; /* Rounded corners */
  opacity: 1;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.5); /* Shadow for the card */
  width: fit-content; /* Makes the width fit content */
  margin-left: auto; /* Centers the card horizontally */
  margin-right: auto; /* Centers the card horizontally */
  text-align: center; /* Centers the text inside */
}



.highlights-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  max-width: 90%;
  margin: 0 auto;
}

.highlight-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1;
  padding:2%;
}

.highlight-circle1,
.highlight-circle2,
.highlight-circle3,
.highlight-circle4 {
  background-color: #eef6fa;
  color: #005bb5; /* Darker blue for contrast */
  font-size: 2rem;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.lines-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  transform: translate(-50%, -50%);
  z-index: -1; /* Moved behind the text content */
}
.highlight-description{
  margin-top: 0;
}
.vertical-line {
  background-color: #88C057; /* Slightly darker line */
  width: 4px;
  height: 100%;
  position: absolute;
  left: 50%;
}

.horizontal-line {
  background-color: #88C057;
  height: 4px;
  width: 60%;
  position: absolute;
  top: 50%;
  left: 20%;
}

/* Responsive styles for 1024px */
@media screen and (max-width: 1024px) {
  
  .internship-title {
    font-size: 2rem;
  }

  .section-title {
    font-size: 2rem;
    text-align: center;
  }

  .highlights-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

/* Responsive styles for 768px */
@media screen and (max-width: 768px) {
  
  .internship-title {
    font-size: 1.8rem;
  }

  .section-title {
    font-size: 2.75rem;
    text-align: center;
  }
  .highlight-title{
    font-size: 2.5rem;
  }
  .highlight-description{
    font-size: 2.2rem;
  }
  .highlights-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .highlight-circle1,
  .highlight-circle2,
  .highlight-circle3,
  .highlight-circle4 {
    width: 70px;
    height: 70px;
    font-size: 1.5rem;
  }
}
@media screen and (min-width:781px) and (max-width:1024px)
{
  .highlight-description{
    font-size: 1.5rem;
  }
  .highlight-title{
    font-size: 1.75rem;
  }
  .highlights-grid{
    font-size: 3em;
  }
}

/* Responsive styles for 480px */
@media screen and (max-width: 480px) {
  
  .internship-container1 {
    padding: 10px;
  }

  .internship-title {
    font-size: 2.25rem;
  }

  .section-title {
    font-size: 2.25rem;
    text-align: center;
  }

  .highlights-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .highlight-circle1,
  .highlight-circle2,
  .highlight-circle3,
  .highlight-circle4 {
    display: none;
  }

  .resume-heading1 {
    font-size: 16px;
  }
}


.highlight-circle1,
.highlight-circle2,
.highlight-circle3,
.highlight-circle4 {
  background-color: #eef6fa;
  color: #005bb5; /* Darker blue for contrast */
  font-size: 2rem;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border: 5px solid rgb(190, 212, 172); /* White circular border */
  box-shadow: 
    0 0 5px rgba(255, 255, 255, 0.8),  /* Light glow around white border */
    0 0 0 10px #547698; /* Grey outer border */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .highlight-circle1,
  .highlight-circle2,
  .highlight-circle3,
  .highlight-circle4 {
    width: 70px;
    height: 70px;
    font-size: 1.5rem;
    border: 5px solid rgb(190, 212, 172); /* Adjust white border for smaller screens */
    box-shadow: 
      0 0 4px rgba(255, 255, 255, 0.8), /* Light glow around white */
      0 0 0 8px #547698; /* Adjusted grey border */
  }
}

@media screen and (max-width: 480px) {
  .highlight-circle1,
  .highlight-circle2,
  .highlight-circle3,
  .highlight-circle4 {
    width: 60px;
    height: 60px;
    font-size: 1.2rem;
    border: 5px solid rgb(190, 212, 172);
    box-shadow: 
      0 0 3px rgba(255, 255, 255, 0.8), /* Subtle glow */
      0 0 0 6px #547698; /* Adjusted grey border for smaller screens */
  }
}
