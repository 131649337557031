/* MoonCoder.css */
.moon-coder {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(to bottom, #a9d85d5e, rgba(255, 255, 255, 0.2));
  color: #121212;
  padding: 5rem;
  margin: 0 auto;
}

.moon-coderofmoon-coder{
  margin-bottom: 2rem;
}

.back-button1 {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.header1ofmoon-coder {
  text-align: center;
  margin-bottom: 40px;
}

.mainTitle1ofmoon-coder {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: darkslategray;
}

.mentorpara3ofmoon-coder {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.content-containerofmoon-coder {
  display: flex;
  gap: 2rem;
  margin: 2rem 0;
  padding: 0 2rem;
}

.image-columnofmoon-coder {
  flex: 1;
  max-width: 50%;
}

.rotating-imageofmoon-coder {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out;
}

.features-columnofmoon-coder {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.box2ofmoon-coder {
  min-height: 60px;
  position: relative;
  border-radius: 20px;
  background: linear-gradient(to right, #9bc655 35%, #8caf57 100%);
  padding: 0.8rem 1rem;
  color: #0E2031;
  box-shadow: 8px 10px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.box2ofmoon-coder:hover {
  transform: scale(1.05);
}

.shadow2ofmoon-coder {
  position: relative;
}

.circleofmoon-coder {
  width: 12px;
  height: 12px;
  background-color: #0E2031;
  border-radius: 50%;
  margin-right: 15px;
}

.content2ofmoon-coder {
  font-size: 1.5rem;
  margin: 0;
  flex: 1;
}

.moon-coder-programofmoon-coder {
  margin: 3rem auto;
  max-width: 1000px;
  padding: 0 2rem;
}

.moon-coder-descriptionofmoon-coder {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  padding: 1rem;
  border-left: 4px solid #3C5C7B; /* Adds a colored left border */
  background-color: #f9f9f9; /* Light background to distinguish */
  border-radius: 8px;
  box-shadow: 8px 10px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.moon-coder-descriptionofmoon-coder:hover { 
transform: scale(1.05);
}

.moon-coder-descriptionofmoon-coder strong {
  color: #3C5C7B;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.moon-coder-descriptionofmoon-coder strong::before {
  content: "★"; /* Adds a star icon before each heading */
  color: #3C5C7B;
  font-size: 1.8rem;
  margin-right: 0.5rem;
}

.moon-coder-descriptionofmoon-coder p {
  margin-left: 1.5rem;
  color: #333;
}

.image-galleryofmoon-coder {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
  padding: 0 2rem;
}

.image-itemofmoon-coder {
  text-align: center;
  max-width: 300px;
}

.image-thumbnailofmoon-coder {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.image-thumbnailofmoon-coder:hover {
  transform: scale(1.05);
}

.action-button {
  background-color: #4CAF50;
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  font-weight: 500;
}

.action-button:hover {
  background-color: #45a049;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .content-containerofmoon-coder {
    padding: 0 1rem;
  }

  .mainTitle1ofmoon-coder {
    font-size: 3.5rem;
  }

  .box2ofmoon-coder {
    padding: 0.6rem 0.8rem;
  }

  .content2ofmoon-coder {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .moon-coderofmoon-coder {
    padding: 1rem;
    margin-bottom: 3rem;
  }

  .content-containerofmoon-coder {
    flex-direction: column;
    gap: 1.5rem;
  }

  .image-columnofmoon-coder {
    max-width: 100%;
  }

  .features-columnofmoon-coder {
    width: 100%;
  }

  .mainTitle1ofmoon-coder {
    font-size: 3.5rem;
  }

  .mentorpara3ofmoon-coder {
    font-size: 1.1rem;
    padding: 0 1rem;
  }

  .moon-coder-programofmoon-coder {
    padding: 0 1rem;
  }

  .image-galleryofmoon-coder {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .image-itemofmoon-coder {
    width: 100%;
    max-width: 280px;
  }

  .box2ofmoon-coder {
    width: 100%;
    min-width: unset;
  }
}

@media (max-width: 480px) {
  .mainTitle1ofmoon-coder {
    font-size: 2.5rem;
  }

  .mentorpara3ofmoon-coder {
    font-size: 1rem;
  }

  .content2ofmoon-coder {
    font-size: 1.8rem;
  }

  .moon-coder-descriptionofmoon-coder {
    font-size: 1rem;
  }

  .action-button {
    padding: 0.6rem 1.2rem;
    font-size: 2.5rem;
    background-color: #45a049;
  }
}

@media (max-width: 1024px) {
  .content-containerofmoon-coder {
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 1rem;
    align-items: center;
  }

  .image-columnofmoon-coder {
    max-width: 100%;
  }

  .features-columnofmoon-coder {
    width: 75%;
  }

  .mainTitle1ofmoon-coder {
    font-size: 2.5rem;
    text-align: center;
  }

  .mentorpara3ofmoon-coder {
    font-size: 1.1rem;
    padding: 0 1rem;
    text-align: center;
  }

  .moon-coder-programofmoon-coder {
    padding: 0 1rem;
  }

  .image-galleryofmoon-coder {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  .image-itemofmoon-coder {
    width: 100%;
    max-width: 280px;
  }

  .box2ofmoon-coder {
    width: 100%;
    min-width: unset;
  }
}
