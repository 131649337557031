.carouselss-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 10%;
  overflow: hidden;
}

.carouselss-container h2 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 2rem;
}


.carousel-wrapper {
  padding: 0 1rem;
  margin-bottom: 3rem;
}

.carousel-container {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-bottom: 1.5rem;
  width: 100%;
}

.review-item {
  width: 250px;
  min-width: 250px;
  height: 333px;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: 0 0 20px rgba(74, 68, 69, 0.7), /* Outer pink glow */
                0 0 30px rgba(219, 219, 219, 0.5);
}

.review-item:hover {
  transform: translateY(-5px);
}

.review-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: .75rem solid rgb(207, 207, 207);
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(74, 68, 69, 0.7), /* Outer pink glow */
                0 0 30px rgba(0, 0, 0, 0.5);
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.carousel-button {
  background: #8CC63F;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 20px;
}

.carousel-button:hover {
  background: #a4eb47;
}

@media screen and (max-width: 1024px) {
  .review-item {
    width: 220px;
    min-width: 220px;
    height: 293px;
  }
}

@media screen and (max-width: 768px) {
  .review-item {
    width: 200px;
    min-width: 200px;
    height: 267px;
  }

  .carouselss-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 20%;
    overflow: hidden;
  }
}

@media screen and (max-width: 480px) {
  .review-item {
    width: 280px;
    min-width: 280px;
    height: 373px;
  }

  .carouselss-container h2 {
    font-size: 2rem;
  }

  .carousel-button {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }
}