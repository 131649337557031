/* Footer.css */
.footer {
  background-image: url('/public/images/Footerbg.png');
  color: #fff;
  padding: 40px 0px;
  text-align: center;
  position: relative;
  height: 500px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 400px;
  position: relative;
  z-index: 0;
}

.location-box {
  background-color: #E1FFB4;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  width: 700px;
  margin: 0 auto;
  text-align: left;
  position: absolute;
  top: -190px;
  left: 50%;
  transform: translateX(-50%) translateY(20px);
  z-index: 1;
  color: #2c3e50;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.location-box:hover {
  transform: translateX(-50%) translateY(18px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.6);
}

.location-box h3 {
  font-size: 28px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #333;
  margin-bottom: 15px;
  border-bottom: 2px solid rgba(44, 62, 80, 0.1);
  padding-bottom: 10px;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.location-box h3 i.fas.fa-map-marker-alt {
  font-size: 40px;
  animation: pulse 1s infinite;
  color: #526E8A;
}

.location-box .footerkaaddress {
  line-height: 1.4;
  position: relative;
  z-index: 2;
  font-size: 20px;
  display: inline-block; 
  text-align: left; 
  font-weight: bold;
  padding-left: 50px;
}

.white-square {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 40px;
  right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  border-radius: 20px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.white-square:hover {
  transform: scale(1.02);
}

.map-container {
  width: 100%;
  height: 80%;
  border: none;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-links ul {
  list-style: none;
  padding: 30px;
  margin-top: 110px;
  display: flex;
  justify-content: center;
  gap: 40px;
}

.footer-links li {
  display: inline;
  margin: 0 30px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
  position: relative;
  padding-bottom: 3px;
}

.footer-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transition: width 0.3s ease;
}

.footer-links a:hover::after {
  width: 100%;
}

.footer-social {
  margin-bottom: 20px;
}

.footer-social h3 {
  margin-bottom: 15px;
  font-size: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.social-icons a {
  font-size: 1.5em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #ffffff;
  transform: translateY(0);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-icons a:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
}

.footer-logo {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.footer-logo img {
  transition: transform 0.3s ease;
}

.footer-logo img:hover {
  transform: scale(1.1);
}

.footer-logo img:first-child {
  width: 90px;
  height: 85px;
}

.footer-logo .optus {
  height: 78px;
  margin-bottom: 4px;
  width: 90px;
  border-radius: 7%;
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
}

.facebook {
  color: #0469ee;
}

.instagram {
  color: #f60531;
}

.linkedin {
  color: #0594e0;
}

/* Media Queries */
@media (min-width: 1200px) {
  .location-box {
    width: 700px;
  }
  
  .location-box .white-square {
    width: 350px;
    height: 230px;
  }
}

/* Adjust only font sizes and images for responsiveness */
@media (max-width: 1199px) {
  .location-box {
    width: 55%;
    top: -170px;
  }

  .location-box h3 {
    font-size: 26px;
  }

  .location-box .footerkaaddress {
    font-size: 18px;
    padding-left: 15px;
    font-weight: bold;
  }

  .white-square {
    width: 300px;
    height: 200px;
  }
}

/* Medium-Large Screens (992px to 1199px) */
@media (min-width: 992px) and (max-width: 1199px) {
  .location-box {
    width: 750px;
  }
  
  .location-box .white-square {
    width: 420px;
    height: 200px;
  }
}

/* Medium Screens (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {

  .footer {
    height: auto;
    min-height: 440px;
    padding: 20px 0;
  }

  .footer-container {
    height: auto;
    min-height: 350px;
    padding: 0 15px;
  }

  .location-box {
    width: 80%;
    max-width: 700px;
    top: -150px;
    padding: 15px;
  }

  .location-box h3 {
    font-size: 24px;
    gap: 15px;
    flex-wrap: wrap;
  }

  .location-box .footerkaaddress {
    font-size: 16px;
  }

  .location-box h3 {
    font-size: 22px;
  }

  .location-box h3 i.fas.fa-map-marker-alt {
    font-size: 26px;
  }

  .location-box .footerkaaddress {
    font-size: 15px;
    padding-left: 15px;
  }

  .location-box .white-square {
    width: 350px;
    height: 180px;
    right: 20px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .footer {
    height: auto;
    padding: 25px 0;
  }

  .location-box {
    width: 90%;
    max-width: 600px;
    top: -130px;
    padding: 15px;
  }

  .location-box h3 {
    font-size: 20px;
    gap: 20px;
  }

  .location-box h3 i.fas.fa-map-marker-alt {
    font-size: 24px;
  }

  .location-box .footerkaaddress {
    font-size: 13px;
  }

  .location-box .white-square {
    width: 200px;
    height: 180px;
    right: 15px;
    top: 35px;

  }

  .footer-links li {
    margin: 0 20px;
  }

  .footer-links a {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .footer {
    height: auto;
    padding: 20px 0;
  }

  .location-box {
    width: 95%;
    max-width: 320px;
    top: -80px; /* Adjusted to avoid overlap */
    padding: 10px;
  }

  .location-box h3 {
    font-size: 18px;
    gap: 15px;
  }

  .location-box h3 i.fas.fa-map-marker-alt {
    font-size: 20px;
  }

  .location-box .footerkaaddress {
    font-size: 12px;
  }

  .location-box .white-square {
    width: 150px;
    height: 140px;
    right: 10px;
    top: 25px;
  }

  .footer-links {
    text-align: center;
    padding: 10px 0;
  }

  .footer-links ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    padding: 0;
  }

  .footer-links li:nth-child(3) {
    flex-basis: 50%; /* Ensures the third item starts on a new line */
    text-align: center;
    margin-top: 10px;
  }

  .footer-links a {
    font-size: 16px;
    text-decoration: none;
  }
}
.white-square {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 30px;
  right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  border-radius: 20px;
  overflow: hidden;
}

.map-container {
  width: 100%;
  height: 80%;
  border: none;
  border-radius: 20px;
}
@media (max-width: 481px) and (max-width: 580px) {
  .footer {
    height: auto;
    padding: 20px 0;
  }

  .location-box {
    width: 95%;
    max-width: 320px;
    top: -130px; /* Adjusted to avoid overlap */
    padding: 10px;
    padding-right: 30px;
  }

  .location-box h3 {
    font-size: 10px;
    gap: 15px;
  }

  .location-box h3 i.fas.fa-map-marker-alt {
    font-size: 30px;
  }

  .location-box .footerkaaddress {
    font-size: 10px;
    padding-left: 5px;
  }

  .location-box .white-square {
    width: 150px;
    height: 140px;
    right: 10px;
    top: 25px;
    
  }
  
  .footer-links {
    text-align: center;
    padding: 10px 0;
  }

  .footer-links ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    gap: 10px;
    padding: 0;
    /* margin: 0; */

  }

  .footer-links li:nth-child(3) {
    flex-basis: 50%; /* Ensures the third item starts on a new line */
    text-align: center;
    margin-top: 10px;
  }

  .footer-links a {
    font-size: 16px;
    text-decoration: none;
  }
}
.white-square {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 35px;
  right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  border-radius: 20px;
  overflow: hidden;
}