/* General Styles */
.college-program-page{
  background-color: #f9f9f9;
}

/* Header and Font Adjustments */
.college-program-page h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

.college-program-page h1 {
  font-size: 3rem; /* Larger for header */
  margin-bottom: 1rem;
}

.college-program-page h2 {
  font-size: 2.5rem;
}

.college-program-page .collegekah {
  font-size: 2rem;
}

.college-program-page p {
  font-size: 1.5rem;
  line-height: 1.5;
}

/* Buttons */
.view-btn {
  background-color: white;
  color: black;
  border: 2px solid black;
  margin: 2% 0px;
  padding: 10px 25px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.view-btn:hover {
  background-color: rgb(105, 105, 105);
  color: white;
}

/* Cards Section */
.cards-section {
  margin: 90px 0 0;
  padding: 5% 3%;
  position:relative;
  z-index: 1;
  /* background: linear-gradient(to bottom, #a9d85d5e, rgba(255, 255, 255, 0.3)); */
  /* background: linear-gradient(90deg, #8fc7ff, #ffffff, #3C5C7B); */
}
.cards-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/images/bluedotedlines.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1; /* Moved background behind the content */
  transform: rotate(0deg);
  background-blend-mode: overlay;
  opacity: 0.35;
   /* Reduced opacity for better contrast */
  
}
.greeting-container {
  display: flex;
  flex-direction: column; /* Default for mobile */
  align-items: center;
  gap: 1.5%;
  max-width: 1200px;
  margin: 0 auto;
}

.highlight10{
  font-size: 1em;
  color:#99C05F;
}
.highlight11{
  font-size: 3em;
}
/* Default styles for greeting cards */
.greeting-card {
  padding: 20px;
  background-color: #C6E894;
  border-radius: 8px;
  
  text-align: center;
  transition: transform 0.3s ease;
  width: 100%; /* Full width on mobile */
  max-width: 300px; /* Maximum width for the card */
  margin:1.25%;
  box-shadow: 0 0 20px rgba(74, 68, 69, 0.7), /* Outer pink glow */
                0 0 30px rgba(255, 255, 255, 0.5);
}

.greeting-card:hover {
  transform: translateY(-3%);
}



.hours-text {
  font-size: 25px;
  color: black;
}

.hours-line {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 15px;
  flex-wrap: wrap; /* Allow wrapping if needed */
}

/* Make the clock emoji much larger */
.clock-emoji {
  font-size: 4rem; /* Significantly larger base size */
  display: inline-block;
  transition: transform 0.3s ease;
  margin: 10px 0; /* Add some vertical spacing */
}

.clock-emoji:hover {
  transform: rotate(360deg);
}
.program-text {
  font-size: 20px;
  color: black;
  font-weight: bolder;
  margin: 5px auto;
}

/* Moments Section */
.moments-section {
  text-align: center;
  background: linear-gradient(90deg, #C6E894, #ffffff, #99C05F);
  padding: 0.75% 0px 1.5%;
  position: relative;
}

.sections-title {
  font-size: 2.3rem;
}

.moments-content {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between;
  gap: 1%;
  max-width: 1200px;
  margin: 2% auto 0;
  padding: 20px;
  position: relative;
  transition: transform 0.5s ease-in-out;
}

.descriptionss {
  flex: 0 1 45%;
  text-align: left;
  font-size: 1.25rem;
}

/* Images container */
.images {
  flex: 0 1 49%;
  position: relative;
  height: 500px; /* Adjust based on your needs */
  margin-left: 1%;
  transition: transform 0.5s ease-in-out;
}
.images:hover{
  transform:scale(1.05);
  
}

.moment-image {
  position: absolute;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  width: 300px;
  height: 400px;
  object-fit: cover;
  border: #ffffff;
  margin-right:2%;
  border: .75rem solid rgb(207, 207, 207);
  box-sizing: border-box;
}

/* Position the images */
.moment-image:first-child {
  top: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0 0 20px rgba(74, 68, 69, 0.7), /* Outer pink glow */
                0 0 30px rgba(213, 202, 202, 0.5);
}

.moment-image:last-child {
  bottom: 0;
  right: 0;
  z-index: 2;
  box-shadow: 0 0 20px rgba(74, 68, 69, 0.7), /* Outer pink glow */
                0 0 30px rgba(18, 17, 17, 0.5);
}

/* Navigation buttons */
.navigation-arrows {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
}

.nav-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #6B8FB2; /* Bluish color matching the image */
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.nav-button:hover {
  background-color: #5A7A99;
}


/* Associations Section */
.associations-section {
  padding: 0 0 5%;
  text-align: center;
  background-color: #f9f9f9;
}

.logos-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Wrap items for responsiveness */
  gap: 12%;
}
.associations-section h2{
  font-size: 2.3rem;
  margin:5% auto 2%;
}

.logos-grid img {
  width: 150px;
  height: auto;
  margin:1rem 3rem;
  object-fit: contain;
  transition: transform 0.3s;
}

.logos-grid img:hover {
  transform: scale(1.2);
}

/* Testimonial Section */
.testimonial-section {
  padding: 3% 3% 8%;
  
  background: linear-gradient(90deg, #C6E894, #ffffff, #99C05F);
}

.headname {
  font-size: 2.3rem;
  text-align: center;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.testimonial-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 30px;
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 2rem;
  }
  .associations-section h2{
    font-size: 2rem;
    
  }
  
  .hours-text {
    font-size: 2.5;
  }
  
  .clock-emoji {
    font-size: 1.75rem;
  }

  .program-text {
    font-size: 2rem;
  }

  .testimonial-card {
    padding: 20px;
  }
  .moments-content {
    flex-direction: column;
    align-items: center;
  }

  .images {
    flex: none;
    width: 100%;
    height: auto;
    margin-left: 0;
  }

  .moment-image {
    position: static;
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }

  .descriptionss {
    flex: none;
    width: 100%;
    margin-bottom: 20px;
  }
  .logos-grid img {
    width: 90px;
  }
}

/* Tablet and Mobile View (up to 768px) */
@media (max-width: 768px) and (min-width:481px) {
  .greeting-container {
    flex-direction: column;
  }
  
  .greeting-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .hours-text {
    font-size: 1.75rem;
  }
  
  .clock-emoji {
    font-size: 1.75rem;
  }

  .program-text {
    font-size: 1.3rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }
  .associations-section h2{
    font-size: 2rem;
    
  }

  .logos-grid img {
    width: 90px;
  }

  .testimonial-card {
    padding: 20px;
  }
  .moments-content {
    flex-direction: column;
    align-items: center;
  }

  .images {
    flex: none;
    width: 100%;
    height: auto;
    margin-left: 0;
  }

  .moment-image {
    position: static;
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }

  .descriptionss {
    flex: none;
    width: 100%;
    margin-bottom: 20px;
  }
}

/* Desktop View (above 768px) */
@media (min-width: 769px) {
  .greeting-container {
    flex-direction: row; /* Cards in a row */
    justify-content: center;
    flex-wrap: wrap; /* Allow wrapping if needed */
  }
  
  .greeting-card {
    flex: 0 1 300px; /* Don't grow, can shrink, base width 300px */
  }

  .hours-text {
    font-size: 2rem;
  }
  
  .clock-emoji {
    font-size: 2rem;
  }

  .program-text {
    font-size: 1.5rem;
  }

  h1 {
    font-size: 2.75rem;
  }

  h2 {
    font-size: 2.25rem;
  }
  .associations-section h2{
    font-size: 2.25rem;
    
  }

  .logos-grid img {
    width: 180px;
  }
}

/* Highlight class for special text */
.highlight {
  color: #C6E894;
}