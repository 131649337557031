.category-page {
  min-height: 200vh;
  background: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(255, 255, 255, 1) 100%), 
    url('/public/images/bluedotedlines.png');
  justify-content: center;
  align-items: center;
  background-size: contain; 
  background-repeat: repeat; 
  background-position: center;
  flex-wrap: wrap;
  width: 100%;
}

.header {
  background: linear-gradient(0deg, rgb(171, 222, 117), rgb(44, 71, 86)) 0% 0% / 300% 300%;  color: white;
  padding: .1rem 0;
  position: relative;
  margin-top: 5rem;
  min-height: 15rem;
  animation: gradientAnimation 6s ease infinite;
  background-size: 300% 300%;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.header-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-logo {
  width: 100px;
  height: 100px;
  display: block;
  margin: 2rem auto 1rem;
}

.back-button {
  position: absolute;
  left: 1rem;
  top: 3rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  background-color: rgb(136, 192, 87);
  transition: transform 0.2s ease;
  z-index: 1;
}

.back-button:hover {
  transform: translateY(-2px);
}

.back-button svg {
  stroke: rgb(33, 17, 17);
  width: 24px;
  height: 24px;
}

.header-subject-title {
  font-size: clamp(2.5rem, 5vw, 3rem) !important;
  text-align: center;
  margin: 0 3rem !important;
  font-weight: bold;
  color: white !important;
  position: relative;
  z-index: 1;
}


.category-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  margin-bottom: 220px;
}

.image-container {
  max-width: 85%;
  margin: 2rem auto;
  background: white;
  padding: 0.2rem;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 1);
  width: 110rem;
  height: auto; /* Changed from fixed height to auto */
  display: flex;
  justify-content: center;
  align-items: center; /* Changed from flex-start to center */
  position: relative;
  overflow: visible; /* Changed from hidden to visible */
}

.category-image {
  width: 100%;
  height: auto; /* Changed from max-height to auto */
  object-fit: contain;
  border-radius: 8px;
  position: relative; /* Changed from absolute to relative */
  margin: auto;
}


/* .category-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}


.image-container {
  max-width: 85%;
  margin: 2rem auto;
  background: white;
  padding: 0.2rem;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 1);
  width: 110rem;
  height: 250rem;
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  position: relative; 
  overflow: hidden; 
}

.category-image {
  width: 100%;
  max-height: 100%; 
  object-fit: contain;
  border-radius: 8px;
  position: absolute; 
  top: 0;
  left: 0;
  right: 0; 
  margin: auto;
} */
.category-page::before,
.category-page::after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  background-image: radial-gradient(circle, #e0e0e0 2px, transparent 3px);
  background-size: 30px 30px;
  opacity: 0.3;
  z-index: -2;
}
.category-page::before {
  top: 50px;
  left: 0;
}
.category-page::after {
  bottom: 50px;
  right: 0;
}

@media screen and (max-width: 360px) {
  .category-page {
    min-height: 200vh;
  }

  .header {
    padding: 1.2rem 0;
    margin-top: 10rem;
  }

  .header-logo {
    width: 70px;
    height: 70px;
  }

  .header-subject-title {
    font-size: clamp(3.4rem, 3vw, 7.8rem) !important;
    margin: 6rem 2rem;
  }
  .back-button {
    top: 2rem;
    left: 2.5rem;
    padding: 0.4rem 1.2rem;
  }
  
  .back-button svg {
    width: 25px;
    height: 25px;
  }

  .image-container {
    max-width: 100%;
    margin: 2rem auto;
    background: white;
    padding: 0.2rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 1);
    width: 110rem;
    height: 105rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow: hidden; 
  }
  
  .category-image {
    width: 100%;
    height: auto;
    max-height: 100%; 
    object-fit: contain;
    border-radius: 8px;
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin: auto; 
  }
}

@media screen and (min-width: 361px) and (max-width: 480px) {
  .category-page {
    min-height: 200vh;
  }

  .header {
    padding: 1.2rem 0;
    margin-top: 10rem;
  }

  .header-logo {
    width: 70px;
    height: 70px;
  }

  .header-subject-title {
    font-size: clamp(3.4rem, 3vw, 7.8rem) !important;
    margin: 6rem 2rem;
  }
  .back-button {
    top: 2rem;
    left: 2.5rem;
    padding: 0.4rem 1.2rem;
  }
  
  .back-button svg {
    width: 30px;
    height: 30px;
  }

  .image-container {
    max-width: 100%;
    margin: 2rem auto;
    background: white;
    padding: 0.2rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 1);
    width: 110rem;
    height: 105rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow: hidden; 
  }
  
  .category-image {
    width: 100%;
    height: auto;
    max-height: 100%; 
    object-fit: contain;
    border-radius: 8px;
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin: auto; 
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .category-page {
    min-height: 200vh;
    background-size: contain;
  }
  .header {
    padding: 1rem 0;
    margin-top:5rem;
  }
  .header-logo {
    width: 70px;
    height: 70px;
    margin: 1rem auto;
  }
  .header-subject-title {
    font-size: clamp(3.4rem, 3vw, 7.8rem) !important;
    margin: 5rem 2rem;
  }
  .back-button {
    top: 2rem;
    left: 2.5rem;
    padding: 0.4rem 1.2rem;
  }
  
  .back-button svg {
    width: 40px;
    height: 40px;
  }
  
  .image-container {
    max-width: 100%;
    margin: 2rem auto;
    background: white;
    padding: 0.2rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 1);
    width: 100rem;
    height: 90rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative; 
    overflow: hidden; 
  }
  
  .category-image {
    width: 100%;
    height: auto; 
    max-height: 100%; 
    object-fit: contain;
    border-radius: 8px;
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0;
    margin: auto; 
  }
  .category-content {
    margin: 0 auto;
    padding: 20px;
    position: relative;
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .category-page {
    min-height: 200vh;
    background-size: contain;
  }
  .header {
    padding: 1rem 0;
    margin-top: 5rem;
  }

  .header-logo {
    width: 70px;
    height: 70px;
  }

  .header-subject-title {
    font-size: clamp(3.4rem, 3vw, 7.8rem) !important;
    margin: 6rem 2rem;
  }
  .back-button {
    top: 2rem;
    left: 2.5rem;
    padding: 0.4rem 1.2rem;
  }
  
  .back-button svg {
    width: 30px;
    height: 30px;
  }
 
  .image-container {
    max-width: 85%;
    margin: 2rem auto;
    background: white;
    padding: 0.2rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 1);
    width: 110rem;
    height: 120rem;
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    position: relative; 
    overflow: hidden; 
  }
  
  .category-image {
    width: 100%;
    height: auto;
    max-height: 100%; 
    object-fit: contain;
    border-radius: 8px;
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin: auto; 
  }
  .category-content {
    margin: 0 auto;
    padding: 20px;
    position: relative;
    margin-bottom: 220px;
  }
}

@media screen and (min-width: 932) and (max-width: 430px) {
  .header {
    padding:  0;
    margin-top: 8rem;
  }

  .header-logo {
    width: 60px;
    height: 60px;
  }

  .header-subject-title {
    font-size: clamp(2.8rem, 4vw, 6.5rem) !important;
    margin: 4rem 1.5rem;
  }

  .back-button {
    top: 5rem;
    left: 2rem;
    padding: 0.3rem 1rem;
  }

  .back-button svg {
    width: 25px;
    height: 25px;
  }

  .image-container {
    max-width: 100%;
    margin: 2rem auto;
    background: white;
    padding: 0.2rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 1);
    width: 10rem;
    height: 130rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow: hidden; 
  }
  
  .category-image {
    width: 100%;
    height: auto;
    max-height: 100%; 
    object-fit: contain;
    border-radius: 8px;
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin: auto; 
  }

}
