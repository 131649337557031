/* marathon.css */
.marathon-container {
    text-align: center;
    font-family: poppins;
    padding: 20px;


    background-image: url('/public/images/bluedotedlines.png');
    background-position: right bottom; /* Position the background image at the bottom-right corner */
    background-repeat: no-repeat;
    background-size: 1500px 600px; /* Adjust the size of the background image */

    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center horizontally */
    justify-content: flex-start; /* Align items from the top */
    padding-bottom: 150px; /* Padding for the section */
    
    z-index: 1;
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.5);
    background: linear-gradient(to bottom, #a9d85d4a, #ffffff);

}

.marathonHeader{
  margin-top: 100px;
    font-size: 3rem;
    margin-bottom: 5%;
    color: #000;
}

h2 {
    font-size: .3rem;
    margin: 5% 0 3%;
    color: #000;
}

.marathon-buttons {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

.marathon-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #8bc34a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
}

.marathon-buttons1 {
  text-align: center; /* Center the buttons horizontally */
  margin-top: 30px; /* Add some margin at the top */
}

.marathon-buttons1 button {
  font-size: 18px; /* Increase font size to make buttons bigger */
  padding: 15px 30px; /* Increase padding for larger buttons */
  margin: 10px; /* Space out the buttons */
  background-color: #A8D85D; /* Green color */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  display: inline-block; /* Display buttons inline but centered */
}

.marathon-buttons1 button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}



.marathon-buttons1 button:hover {
  transform: scale(1.1); /* Slightly grow when hovered */
  background-color: #3C5C7B; /* Darker shade for hover effect */
}

.marathon-buttons1 button:active {
  background-color: #3C5C7B; /* Even darker shade for click effect */
  transform: scale(1.2); /* Grow further when clicked */
}

.company-specific{
  margin-top: 50px;
  font-size: 2.2em;
}

.marathon-doc-title {
  font-size: 1.3em; /* Adjust font size */
  font-weight: bold; /* Make the title bold */
  color: #333; /* Set a color for the text */
  margin-bottom: 10px; /* Add space below the title */
}



.marathon-button1 {
    display: flex;
    align-items: center;
    padding: 10px 40px;
    font-size: 1rem;
    color: #fff;
    background-color: #8bc34a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
}

.tick-icon {
    font-size: 1.2rem;
    color: white;
}


.marathon-button:hover {
    background-color: #7cb342;
}

.marathon-button:active {
    background-color: #689f38;
}

.back-button1 {
    position: fixed; /* Fixed positioning */
    top: 20px; /* Position it 20px from the top */
    left: 20px; /* Position it 20px from the left */
    background-color: #ffffff;
    color: #000000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Poppins;
    z-index: 200; /* Ensure it stays on top of other content */
  }
  
  .back-button1:hover {
    background-color: #ecf4d0;
  }



  .marathon-buttons-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
    padding: 40px;
  }
  
  .marathon-card {
    background: linear-gradient(135deg, #A8D85D, #99C05F); /* Gradient background */
    /* border: 2px solid #99C05F; */
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: white;
   
  }

  .marathon-buttons-container {
    display: flex; /* Arrange buttons in a row */
    gap: 10px; /* Space between buttons */
    justify-content: center; /* Center the buttons horizontally */
    margin-top: 10px; /* Space between the heading and buttons */
  }
  
  
  .marathon-card h3 {
    margin-bottom: 40px;
    color: white; /* Title color */
    font-weight: bold;
  }
  
  .marathon-doc-button {
    background-color: white;
    color: #99C05F;
    border: none;
    border-radius: 6px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    font:poppins;
  }
  
  .marathon-doc-button:hover {
    background-color: #99C05F; /* Button hover background */
    color: white; /* Button hover text */
  }
  
  /* .back-button1 {
    margin: 10px 0;
    padding: 10px 10px;
    background-color: white;
    color: #99C05F;
    border: 2px solid #99C05F;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
  } */
  
  /* .back-button1:hover {
    background-color: #99C05F;
    color: white;
  }
   */


   @media screen and (max-width: 768px) {
    /* Mobile View for .marathon-container */
    .marathon-container {
      padding: 20px;
      background-size: cover; /* Ensure background image covers the entire area */
      background-position: center; /* Center the background image */
      background-color: rgba(255, 255, 255, 0.8); /* Adjust opacity for better readability */
      flex-direction: column; /* Stack elements vertically */
    }
  
    h1 {
      margin-top: 70px;
      font-size: 2.5rem; /* Smaller font size for header */
      margin-bottom: 10px; /* Reduced margin for header */
    }
  
    h2 {
      font-size: 1.9rem; /* Smaller font size for subheading */
      margin: 30px 0 15px;
    }
    
    .company-specific{
      margin-top: 50px;
      font-size: 3em;
    }

    /* Mobile View for buttons */
    .marathon-doc-button1 {
      display: flex;
      width: 100%; /* Make buttons take up the full width of their container */
      padding: 10px 20px; /* Padding for better spacing inside buttons */
      font-size: 1.5em; /* Uniform font size for buttons */
      background-color: #A8D85D; /* Adjust background color */
      color: white;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease; /* Smooth hover effect */
    }
    
    .marathon-doc-button1:hover {
      background-color: #99C05F; /* Darker shade for hover effect */
    }
    
    
    .marathon-button:hover, .marathon-button:active {
      background-color: #7cb342; /* Hover/active state for button */
    }
    .marathon-doc-title {
      font-size: 2em; /* Adjust font size */
      font-weight: bold; /* Make the title bold */
      color: #333; /* Set a color for the text */
      margin-bottom: 10px; /* Add space below the title */
    }
  
    /* Adjust back button */
    .back-button1 {
      top: 10px;
      left: 10px;
      padding: 8px 16px;
      font-size: 14px; /* Smaller font size */
    }
  
    /* Mobile View for marathon buttons grid */
    .marathon-buttons-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 2 cards in a row */
      gap: 10px; /* Reduced gap between items */
      padding: 0px;
    }
  
    /* Mobile View for marathon cards */
    .marathon-card {
      padding: 6px;
      margin: 1px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* Slightly lighter shadow */
    }
  
    .marathon-card h3 {
      font-size: 2.1rem; /* Smaller title font size */
      margin-bottom: 20px; /* Reduced margin */
    }
  
    .marathon-doc-button {
      padding: 8px 12px;
      font-size: 12px; /* Smaller font size */
    }
  
    .marathon-doc-button:hover {
      background-color: #99C05F;
      color: white;
    }
  }
  


  @media screen and (min-width: 769px) and (max-width: 1024px) {
    /* Tablet View for .marathon-classes-container */
    .marathon-classes-container {
        padding: 40px;
        background-size: cover; /* Ensure background image covers the area */
        background-position: center;
        background-color: rgba(255, 255, 255, 0.6); /* Adjust opacity */
        flex-direction: column;
    }

    .marathon-doc-title {
      font-size: 2em; /* Adjust font size */
      font-weight: bold; /* Make the title bold */
      color: #333; /* Set a color for the text */
      margin-bottom: 10px; /* Add space below the title */
    }

    h1 {
        margin-top: 120px;
        font-size: 2rem; /* Adjusted font size for tablet */
        margin-bottom: 20px;
    }

    h2 {
        font-size: 2rem; /* Slightly smaller for tablet */
        margin: 40px 0 20px;
    }

    .marathon-classes-button, .marathon-classes-button1 {
        padding: 14px 30px;
        font-size: 1.5rem;
        width: 80%; /* Allow buttons to take 80% width */
        text-align: center;
    }

    .marathon-card h3 {
      font-size: 2rem; /* Smaller title font size */
      margin-bottom: 20px; /* Reduced margin */
    }

    /* Tablet View for marathon buttons grid */
    .marathon-classes-buttons-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
        gap: 20px;
        padding: 0px;
    }

    .marathon-classes-card h3 {
        font-size: 1.5rem;
        margin-bottom: 25px;
    }

    .marathon-classes-doc-button {
        padding: 10px 15px;
        font-size: 23px;
    }
}