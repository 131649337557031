.main-container {
  min-height: 100vh;
  background-color: white;
  overflow: hidden;
}

.containeerrs {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0rem;
  overflow-x: hidden;
}

.about-section {
  width: 100%;
}

.about-header {
  background-color: #3C5C7B;
  width: 100;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding: 3rem 1rem;
  padding-top: 8.5%;
  color: white;
  text-align: center;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.about-header h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* .about-header p {
  font-size: 1.25rem;
  max-width: 58rem;
  margin: 0 auto;
} */

/* About Content Section */
.about-content {
  display: flex;
  gap: 4rem;
  padding: 4rem 0;
  align-items: center;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
}

.image-containerss {
  width: 100%;
  max-width: min(100%, 28rem);
  height: auto;
  position: relative;
  z-index: 1;
  aspect-ratio: 1;
}

.image-containerss img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

/* .about-features {
  flex: 1;
} */

.about-features h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1rem;
}

.about-features p {
  font-size: 1.25rem;
  color: #6b7280;
  margin-bottom: 2rem;
}

.features-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

/* Green circle behind feature text */
.feature-item::before {
  content: '';
  width: 3.5rem;
  height: 3.5rem;
  background: linear-gradient(145deg, #dcfce7 0%, #a1e739 100%);
  border-radius: 50%;
  flex-shrink: 0;
}

/* Feature text styling */
.feature-item span {
  font-size: 1rem;
  font-weight: 500;
  color: #1f2937;
  z-index: 1;
}

@media (max-width: 1024px) {
  .about-content {
    flex-direction: column;
    text-align: center;
  }

  .features-grid {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .image-containerss {
    width: 20rem;
    height: 20rem;
  }
}

@media (max-width: 480px) {
  .features-grid {
    grid-template-columns: 1fr;
  }
h1{
  padding-top: 2rem;
}
  .image-containerss {
    width: 16rem;
    height: 16rem;
  }
}

.why-choose-section {
  background-size: cover;
  background-position: center;
  padding: 3rem 0;
  position: relative;
  z-index: 1;
}

.why-choose-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('/public/images/blue doted lines 2.png'); */
  background-size: cover;
  background-position: center;
  opacity: 0.3; 
  z-index: -1;
}

.why-choose-header {
  text-align: center;
  /* margin-bottom: 3rem; */
}

.why-choose-header h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1rem;
}

.highlight {
  color: #8CC63F;
}

.section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.content-wrapper {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.text-content {
  flex: 1;
}

.text-content h2 {
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #1f2937;
}

.benefits-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 0;
}

.benefits-list li {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  text-align: left;
  padding-left: 1rem;
}

.check-mark {
  color: #22c55e;
  flex-shrink: 0;
  margin-top: 0.25rem;
}

.image-content {
  flex: 1;
  z-index: 2; 
}

.placeholder-image {
  width: 90%;
  border-radius: 0.5rem;
}

.give-back-section {
  position: relative;
  background: url('/public/images/Group 35.png') no-repeat center center; 
  background-size: cover; 
  padding: 4rem 0;
  overflow: hidden;
  color: #ffffff; 
}

@media (max-width: 480px) {
  .give-back-section .section-content h2 {
    font-size: 2.8rem !important; /* Increased from 2.5rem */
    margin-bottom: 2rem;
  }

  .give-back-section .section-content p {
    font-size: 2.2rem !important; /* Increased from 1rem */
    line-height: 2.5rem;
    padding: 0 1rem;
  }
}

.section-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  text-align: center;
}

.section-content h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1.5rem;
}

.section-content p {
  font-size: 1rem;
  line-height: 1.75rem;
  color: #374151;
}

.team-section {
  padding: 1rem 0;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  
}

.team-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/images/blue doted lines 2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3; 
  z-index: -1;
}

.team-section h2 {
  font-size: 1.875rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  color: #1f2937;
}

.team-grid {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
}

.team-card {
  background-color: white;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.team-card:hover {
  transform: scale(1.05);
}

.card-header {
  height: 8rem;
  /* background-color: #0E2031; */
  position: relative;
}

.check-mark {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #8CC63F;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.875rem;
}

.image-content {
  flex: 1;
  position: relative;
}


.profile-image {
  width: 6rem;
  height: 6rem;
  /* background-color: #e5e7eb; */
  border: 8px solid white;
  /* border-radius: 50%; */
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;
}

.card-content {
  padding: 2rem 0rem 0rem;
  text-align: center;
}

.card-content h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  color: #1f2937;
}

.card-content p {
  color: #0E2031;

}

/* CTASection.css */
.cta-section {
  background-color: #f8f9fa;
  padding: 4rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.cta-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
}

.cta-title {
  font-size: 2rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.cta-subtitle {
  font-size: 1.25rem;
  color: #4b5563;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.cta-button {
  background-color: #86efac;
  color: #1f2937;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  transform: translateY(-2px);
  background-color: #4ade80;
  box-shadow: 0 6px 8px -1px rgba(0, 0, 0, 0.1);
}

.cta-button:active {
  transform: translateY(0);
}


 @media (max-width: 768px) {
  .cta-section {
    padding: 3rem 0;
  }

  .cta-title {
    font-size: 1.75rem;
  }

  .cta-subtitle {
    font-size: 1.125rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.625rem 1.75rem;
  }
} 

 @media (max-width: 480px) {
  .cta-title {
    font-size: 1.5rem;
  }

  .cta-subtitle {
    font-size: 1rem;
  }

  .cta-container {
    padding: 0 1.5rem;
  }


}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .team-section:nth-child(8) .team-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .team-grid,
  .team-section:nth-child(8) .team-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .team-grid,
  .team-section:nth-child(8) .team-grid {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 1024px) {
  .about-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .content-wrapper {
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 480px) {
  /* Add these new styles */
  .benefits-list li {
    padding-left: 1.5rem;
  }
  
  .benefits-list {
    width: 100%;
  }
  
  .text-content {
    text-align: left;
    width: 100%;
  }
  
  .check-mark {
    font-size: 2rem;
    margin-top: 0.5rem;
  }
}
@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .about-header h1 {
    font-size: 1.75rem;
  }

  .about-features h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .features-grid {
    grid-template-columns: 1fr;
  }

  .about-header p,
  .about-features p {
    font-size: 1rem;
    padding: 0 1rem;
  }

  .about-content {
    padding: 2rem 1rem;
  }
}

@media (max-width: 360px) {
  .contact-card {
    width: 95%;
    padding: 15px;
  }
  
  .about-header {
    padding: 2rem 0.75rem;
  }
}

.cta-section {
  background-color: #f9fafb; /* Light gray background */
  padding: 2rem 1rem;
  text-align: center;
}

.cta-content {
  max-width: 900px;
  margin: 0 auto;
}

.cta-heading {
  font-size: 1.25rem;
  font-weight: 500;
  color: #1f2937; /* Dark gray */
  margin-bottom: 1.5rem;
  line-height: 1.75rem;
}

.cta-button {
  background-color: #a3d977; /* Vibrant green color */
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #8fc65e; /* Slightly darker green for hover */
  transform: translateY(-3px);
}

.cta-button:active {
  transform: translateY(0);
}

@media screen and (max-width: 768px) {
  .cta-heading {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .cta-button {
    padding: 1.6rem 2rem;
    font-size: 2.5rem;
  }
}

/* Contact Card Section */
.card-section-aboutofabout {
  margin-bottom: 300px;
  position: relative;
}

.contact-containerofabout {
  max-width: 500px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.image-sectionofabout {
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}

.image-sectionofabout img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 0px 0px 0px 0px;
}

.contact-sectionofabout {
  background: linear-gradient(90deg, #C6E894, #ffffff, #99C05F);
  padding: 20px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0 2px 5px rgba(75, 72, 72, 0.1);
  margin-bottom: 50%;
}

.contact-headingofabout {
  text-align: center;
  color: #000;
  font-size: 25px;
  font-weight: bold;
  margin: 0 0 20px 0;
  text-transform: uppercase;
}

.contact-itemofabout {
  display: flex;
  align-items: center;
  margin: 15px 0;
  padding: 10px 20px;
  background-color: #ffffff80;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.2s;
}

.contact-itemofabout:hover {
  transform: scale(1.02);
}

.contact-iconofabout {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  background-color: #4CAF50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-textofabout {
  color: #000;
  font-size: 16px;
  text-decoration: none;
}



/* Contact Section */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 10px;
  text-align: center;
  margin-bottom: 150px;
  
}

/* Heading Styles */
.contact-heading-main {
  font-size: 32px;
  font-weight: bold;
  color: #222;
  margin-bottom: 10px;
}

.contact-heading-highlight {
  color: #99C05F;
}

.contact-subtext-info {
  font-size: 16px;
  margin-bottom: 30px;
}

/* Contact Cards Container */
.contact-cards-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  
}

/* Contact Card Styles */
.contact-card-box {
  background: white;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 10px;
  width: 250px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #A8D85D; /* Green border */
}

/* Hover Effect */
.contact-card-box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.15);
}

/* Icon Styling */
.contact-icon-style {
  font-size: 40px;
  margin-bottom: 10px;
}

/* Text Inside Cards */
.contact-title {
  font-size: 20px;
  font-weight: bold;
  color: #222;
  margin-bottom: 5px;
}

.contact-detail {
  font-size: 16px;
  color: #555;
}


@media screen and (max-width: 480px) {
  .contact-card {
    width: 95%;
    padding: 15px;
  }

  .contact-image {
    height: 250px;
    margin-bottom: 30px;
  }
}

.contact-text {
  color: #000 !important;
  font-size: 25px;
  font-weight: bold;
  text-decoration: none;
}

.about-header-text {
  font-size: 1.25rem;
}

.features-intro {
  font-size: 1.25rem;
  color: #6b7280;
  margin-bottom: 2rem;
}

.feature-text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #1f2937;
  z-index: 1;
}

@media (max-width: 480px) {
  .about-header-text {
    font-size: 2rem !important;
    line-height: 2.5rem;
    padding: 0 1rem;
  }

  .contact-text {
    color: #000 !important; /* Normal state */
    font-size: 16px;
    text-decoration: none;
    font-weight: bold;
  }

h1{
  font-size: 3.5rem !important;
    line-height: 3.5rem;
}

  .features-intro {
    font-size: 2rem !important;
    line-height: 2.5rem;
    margin-bottom: 1.5rem;
  }

  .feature-text {
    font-size: 2rem !important;
  }

  .cta-heading {
    font-size: 1.5rem !important;
    line-height: 2rem;
    margin-bottom: 2rem;
  }

  .about-features {
    padding: 0 1rem;
  }

  .features-grid {
    gap: 1.5rem;
  }
}

/* Modify the media queries to only handle essential changes */
@media screen and (max-width: 480px) {
  .ribbon-banner::before,
  .ribbon-banner::after {
    /* Hide decorative elements on small screens */
    display: none;
  }

  .contact-card {
    padding: 15px; 
  }
}












.why-choose-section {
  background-color: #ffffff; /* Clean background color */
  padding: 80px 20px;
  color: #333;
  position: relative;
}

.section-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.why-choose-header h2 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;
  letter-spacing: 1px;
  z-index: 1;
}

.subheader-text {
  font-size: 1.3rem;
  /* color: #555; */
  max-width: 900px;
  text-align: center;
  opacity: 0.75;
}

.highlight {
  color: #74a944; /* Soft blue highlight */
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.content-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  animation: fadeInUp 1s ease-out;
}

.text-content {
  max-width: 80%;
  text-align: center;
}

.intro-text {
  font-size: 1.3rem;
  margin-bottom: 30px;
  color: #555;
}

.benefits-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
}

.benefit-item {
  font-size: 1.2rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: fadeInUp 1.2s ease-out forwards;
  animation-delay: calc(0.2s * var(--i)); /* Delayed animations for items */
}

.check-mark {
  font-size: 1.5rem;
  color: #ffffff;
  margin-right: 15px;
}

.feature-text {
  font-size: 1.2rem;
  color: #333;
  transition: color 0.3s ease;
}

.feature-text strong {
  color: #3a6040;
}

.feature-text:hover {
  color: #74a944;
}

/* Hover and Interactivity */
.benefits-list li:hover .feature-text {
  color: #74a944; /* Soft blue on hover */
}

/* Keyframe Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.benefit-item:nth-child(1) {
  --i: 1;
}

.benefit-item:nth-child(2) {
  --i: 2;
}

.benefit-item:nth-child(3) {
  --i: 3;
}

.benefit-item:nth-child(4) {
  --i: 4;
}

















/* Team Section Styling */
.team-section {
  text-align: center;
  padding: 20px 20px;
  background: linear-gradient(135deg, #0E2031, #3C5C7B); /* Navy Blue Gradient */
  color: white;
}

/* Title Styling */
.team-section h2 {
  font-size: 2.2rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 30px;
}

/* Grid Layout - 5 Cards per Row */
.team-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  justify-content: center;
  padding: 10px;
}

/* Team Card with Glassmorphic Effect */
.team-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 5px;
  text-align: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.564);
  transition: transform .1s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Hover Effect */
.team-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(255, 255, 255, 0.3);
}

/* Profile Image */
.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 15px;
}

/* Card Content */
.card-content h3 {
  font-size: 1.3rem;
  font-weight: bold;
  color: #ffffff;
}

.card-content p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .team-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row on medium screens */
  }
}

@media (max-width: 768px) {
  .team-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row on small screens */
  }
  .card-content{
    margin-top: 20px;
  }

  .intro-text {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #555;
  }
  
  .subheader-text {
    font-size: 2rem;
    /* color: #555; */
    max-width: 900px;
    text-align: center;
    opacity: 0.75;
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 10px;
    text-align: center;
    margin-bottom: 100px;
  }
}

@media (max-width: 480px) {
  .team-grid {
    grid-template-columns: repeat(2, 1fr); /* 1 card per row on mobile */
  }
  .team-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.801);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  .card-content{
    margin-top: 20px;
  }

  .intro-text {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #555;
  }
  
  .subheader-text {
    font-size: 2rem;
    /* color: #555; */
    max-width: 900px;
    text-align: center;
    opacity: 0.75;
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 10px;
    text-align: center;
    margin-bottom: 100px;
  }
}













/* Section with Background Image */

.cta-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background: url('/public/images/about-contact.jpg') no-repeat center center/cover;
}

/* Black Overlay */
.cta-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent black */
  z-index: 1; /* Ensures the overlay is above the background but below the content */
}

/* Glassmorphism Card */
.cta-content {
  z-index: 10;
  position: relative;
  margin-left: 90px;
  padding: 2.4rem;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.22); /* Semi-transparent white */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* Soft shadow */
  backdrop-filter: blur(15px); /* Frosted-glass effect */
  text-align: center;
  max-width: 200px;
  transition: transform 0.3s ease-in-out;
}

/* Soft Glow Effect */
.cta-content::before {
  content: "";
  position: absolute;
  inset: -8px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.6);
  filter: blur(10px);
  z-index: -1;
  animation: softGlow 4s infinite alternate;
}

/* Call-to-Action Button */
.cta-button {
  margin-top: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effects */
.cta-content:hover {
  transform: translateY(-5px);
}

.cta-button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(255, 255, 255, 0.3);
}

/* Soft Glow Animation */
@keyframes softGlow {
  0% {
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  }
  100% {
    box-shadow: 0 0 25px rgba(255, 255, 255, 1);
  }
}

/* Call-to-Action Button */
.cta-button {
  margin-top: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background: #1f2937;
  box-shadow: 0 4px 10px rgba(128, 128, 128, 0.2);
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}




/* Adjustments for Mobile View */
@media (max-width: 768px) {
  .cta-section {
    height: 50vh; /* Decreased height */
    
  }

  .cta-content {
    margin-bottom: -200px;
    margin-left: 10px;
    max-width: 90%;
    padding: 1.5rem; /* Reduce padding for compact look */
  }


/* Call-to-Action Button */
.cta-button {
  margin-top: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background: #1f2937;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effects */
.cta-content:hover {
  transform: translateY(-5px);
}

.cta-button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(255, 255, 255, 0.3);
}

/* Soft Glow Animation */
@keyframes softGlow {
  0% {
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  }
  100% {
    box-shadow: 0 0 25px rgba(255, 255, 255, 1);
  }
}

/* Call-to-Action Button */
.cta-button {
  margin-top: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background: #1f2937; /* Initial grey background */
  box-shadow: 0 4px 10px rgba(128, 128, 128, 0.2);
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}



}









/* Section Styling */


/* Soft Pinkish Overlay to Darken Background */
.about-section::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgb(255, 255, 255); /* Soft pink overlay */
  z-index: -1;
}

/* About Container */
.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 12px;
}

/* Header Section */
.about-header h1 {
  font-size: 2.8rem;
  text-align: center;
  font-weight: 700;
  color: #ffffff; /* Light pastel pink */
  margin-bottom: 1rem;
}


.about-header-text {
  font-size: 1.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin-bottom: 2rem;
  font-style: italic;
}

/* About Content */
.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Ensures image on left and features on right */
  gap: 2rem;
  margin-top: 2rem;
  max-width: 1200px;
  border-radius: 12px;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.9); /* Light background for contrast */
}

/* About Image */
.about-image {
  flex: 1;
  max-width: 500px;
  display: flex;
  justify-content: center;
}

/* About Image */
.about-image img {
  width: 100%;
  max-width: 450px;
  border-radius: 12px;
  animation: scaleAnimation 1s infinite alternate ease-in-out; /* Infinite continuous animation */
}

/* Scaling Animation */
@keyframes scaleAnimation {
  0% {
    transform: scale(1); /* Initial size */
  }
  50% {
    transform: scale(1.05); /* Zoom in slightly */
  }
  100% {
    transform: scale(1); /* Return to original size */
  }
}

/* Features Section */


.about-features h2 {
  font-size: 2rem;
  color: #050209; /* Soft coral color */

}

.features-intro {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 1rem;
}

/* Features Grid */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 8fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.feature-item {
  background: #fff;
  /* padding: 1.5rem; */
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}
/* 
.feature-item:nth-child(even) {
  animation-delay: 0.5s;
}

.feature-item:nth-child(odd) {
  animation-delay: 0.3s;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.feature-text {
  font-size: 1rem;
  color: #444;
  font-weight: 400;
  line-height: 1.5;
} */

/* Fade-in Animation */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}



/* Fade-in Animation */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    text-align: center;
  }

  .about-header h1 {
    font-size: 2rem;
  }

  .about-header-text {
    font-size: 1rem;
  }

  .about-image img {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .about-features {
    padding: 1rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .contact-cards-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 80%;
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 10px;
    text-align: center;
    margin-bottom: 100px;
  }
}






