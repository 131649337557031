/* MentorshipModule.css */

/* General Styles */
.mentorship_main_container {
  font-family: Poppins, sans-serif;
  max-width: 100vw;
  height: auto;
  border-radius: 10px;
  position: relative;
  padding: 20px;
  z-index: 1;
  min-height: 100vh;
  overflow-x: hidden; /* Remove horizontal scroll */
  overflow: hidden;
}

.mentorship_main_container::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url('/public/images/bluedotedlines.png'), linear-gradient(135deg, #88C057, #ffffff);
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  z-index: -1;
  transform: rotate(0deg);
  background-blend-mode: overlay;
  opacity: 0.35;
}

.mentorship_content_wrapper {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  align-items: start;
}

.mentorship_heading {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
  color: #333;
}

/* Back Button Styles */
.back-button1 {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 20px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.title-overlay {
  position: absolute;
  bottom: 0;
  top: 55%;
  left: 0;
  right: 5;
  padding: 2rem 1rem 1rem;
  border-radius: 0 0 1.5rem 1.5rem;
  transform: translateY(100%);
  animation: slideUp 1.5s ease-out forwards;
  will-change: transform;
}

.title-text {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease-out 1s forwards;
  will-change: opacity, transform;
}

/* Keyframes */
@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form Styles */
.mentorship_form_container {
  background: white;
  padding: 2rem;
  border-radius: 1.5rem;
}

.mentorship_form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.mentorship_input,
.mentorship_select,
.mentorship_textarea {
  width: 100%;
  padding: 0.75rem 0;
  border: none;
  border-bottom: 1px solid #e2e8f0;
  border-radius: 0;
  background-color: white;
  font-size: 1.1rem;
  color: #000000;
  transition: all 0.2s ease;
  outline: none; /* Removes focus shadow */
  box-shadow: none !important; /* Ensures no shadow appears */
}

/* Optional: Add focus effect for better UI */
.mentorship_input:focus,
.mentorship_select:focus,
.mentorship_textarea:focus {
  border-bottom: 1px solid #88C057; /* Highlight on focus */
}

.mentorship_input::placeholder,
.mentorship_textarea::placeholder {
  color: #000000;
}

.mentorship_select {
  color: #4a5568;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234a5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1.5rem;
  padding-right: 2rem;
}

.mentorship_select option:first-child {
  color: #a0aec0;
}

.mentorship_input:focus,
.mentorship_select:focus,
.mentorship_textarea:focus {
  outline: none;
  border-bottom-color:#88C057;
  box-shadow: none;
}

.mentorship_textarea {
  min-height: 100px;
  resize: vertical;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
}

.mentorship_submit_btn {
  background:#4CAF50;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mentorship_submit_btn:hover {
  background: #88C057;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Features Styles */
.mentorship_features_container {
  max-width: 1200px;
  margin: 2rem auto 0;
  padding: 0 2rem;
}

.mentorship_images_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 3rem;
}

.mentorship_feature_image {
  width: 100%;
  height: 360px;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.mentorship_feature_image:hover {
  transform: translateY(-10px);
}

.mentorship_features_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.mentorshipfeature {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  padding: 1rem;
  border-left: 4px solid #3C5C7B;
  background-color: #6cc95941;
  border-radius: 50px;
  box-shadow:0 8px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.mentorshipfeature:hover {
  transform: scale(1.05);
}

.mentorshipfeature strong {
  color: #0E2031;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}

/* .mentorshipfeature::before {
  content: "★";
  color: #3C5C7B;
  font-size: 2rem;
  margin-right: 0.5rem;
} */

.mentorshipfeature p {
  margin-left: 1.5rem;
  color: #333;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .mentorship_content_wrapper {
    grid-template-columns: 1fr;
    max-width: 800px;
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .mentorship_heading {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .mentorship_form_container {
    padding: 1rem;
    margin: -1rem;
  }

  .mentorship_content_wrapper {
    padding: 1rem;
  }

  .mentorship_images_container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .mentorship_features_grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .title-text {
    color: white;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    opacity: 0;
    transform: translateY(1px);
    animation: fadeIn 0.5s ease-out 1s forwards;
    will-change: opacity, transform;
  }
}

@media (max-width: 480px) {
  .mentorship_heading {
    font-size: 1.75rem;
  }

  .mentorship_content_wrapper {
    padding: 0.5rem;
  }

  .mentorship_form_container {
    padding: 1rem;
    margin: 1%; 
    max-width: 100%; 
    box-sizing: border-box; 
    width: 90%;
  }
  
  .mentorship_input,
  .mentorship_select,
  .mentorship_textarea {
    font-size: 0.9rem;
  }

  .mentorship_submit_btn {
    padding: 0.75rem;
    font-size: 0.9rem;
  }

}

/* ============================================================================= */

/* Carousel Styles */
.carousel-wrapper {
  margin: 2rem auto;
  max-width: 1320px;
  padding: 0 1rem;
}

.carousel-item {
  padding: 1rem;
  position: relative;
  height: 510px;
}

.carousel-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  aspect-ratio: 16 / 9; /* Adjust this to your required aspect ratio */
  object-fit: cover; /* Ensures the image fills the space */
}


.carousel-content {
  position: absolute;
  bottom: 0;
  left: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.95);
  padding: 1.2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.carousel-content h3 {
  margin: 0;
  color: #333;
  font-size: 1.3rem;
  font-weight: 600;
}

.carousel-content p {
  margin: 0.5rem 0 0;
  color: #666;
  font-size: 0.95rem;
}

/* Book Session Button */
.book-session-btn {
  display: block;
  margin: 2.5rem auto;
  padding: 1.2rem 2.5rem;
  font-size: 1.2rem;
  background-color: #88C057;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.2);
}

.book-session-btn:hover {
  background-color: #73ae3f;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(37, 99, 235, 0.3);
}

/* Form Modal Styles */
.form-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.form-modal-content {
  background: white;
  padding: 2.5rem;
  border-radius: 12px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.modal-close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease;
}

.modal-close-btn:hover {
  color: #333;
}

/* Form Styles */
.mentorship_form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-group {
  position: relative;
}

.Booking{
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: -1.3rem;
  color: #333;
}

.mentorship_input,
.mentorship_select,
.mentorship_textarea {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.mentorship_input:focus,
.mentorship_select:focus,
.mentorship_textarea:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.mentorship_textarea {
  min-height: 60px;
  resize: vertical;
}

.error-message-Of-Mentorship {
  color: #dc2626;
  font-size: 0.9rem;
  margin-top: 0.2rem;
}

.mentorship_input.error,
.mentorship_select.error,
.mentorship_textarea.error {
  border-color: #dc2626;
}

/* Success Popup Styles */
.success-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.success-popup {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.success-icon {
  font-size: 3rem;
  color: #22c55e;
  margin-bottom: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .mentorship_heading {
      font-size: 2rem;
  }

  .carousel-item {
      height: 450px;
  }

  .carousel-image {
      height: 350px;
  }

  .form-modal-content {
      padding: 1.5rem;
      width: 95%;
  }

  .book-session-btn {
      padding: 1rem 2rem;
  }
}

@media (max-width: 480px) {
  .mentorship_heading {
      font-size: 1rem;
  }

  .carousel-item {
      height: 300px;
  }

  .carousel-image {
      height: 200px;
      aspect-ratio: 16/9;
      object-fit: cover;
  }

  .book-session-btn {
      padding: 0.8rem 1.5rem;
      font-size: 1rem;
  }
}

/* -------------------------------------------------------------- */

.carousel-container {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent 0%,
    rgb(0, 0, 0) 10%,
    rgb(0, 0, 0) 90%,
    transparent 100%
  );
  mask: linear-gradient(
    90deg,
    transparent 0%,
    rgb(0, 0, 0) 10%,
    rgb(0, 0, 0) 90%,
    transparent 100%
  );
}

.alice-carousel__stage {
  gap: 1rem;
  animation: smoothScroll 35s linear infinite;
}

@keyframes smoothScroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-300%);
  }
}

.mentorship_feature_image {
  max-width: 100%;
  height: auto;
}
