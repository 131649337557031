
html {
  font-size: 100%;
}

.review-title1 {
  text-align: center;
  font-size: clamp(2rem, 3.5vw, 4rem);
  padding: 2.5rem 1.25rem;
  margin: 1% auto 0;
  max-width: 75rem;
}

.container {
  font: poppins;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 6.25rem 2%;
  background-size: cover;
  background-position: center;
  width: 100%;
  max-width: 112.5rem;
  margin: 0 auto;
  overflow: hidden;
  background-color: #3C5C7B;
}

.container > .btn {
  position: relative;
  width: 6.25rem;
  height: 6.25rem;
  margin: 1.25rem;
  padding: 0rem 3.75rem;
}

.container > .btn > a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0.94rem 2.19rem rgba(0, 0, 0, 0.2);
  border-top: 0.06rem solid rgba(255, 255, 255, 0.1);
  border-bottom: 0.06rem solid rgba(255, 255, 255, 0.1);
  border-radius: 1.875rem;
  color: #fff;
  z-index: 1;
  font-weight: 600;
  letter-spacing: 0.06rem;
  text-decoration: none;
  overflow: hidden;
  transition: 0.75s;
  backdrop-filter: blur(0.94rem);
}

.container > .btn:hover > a {
  letter-spacing: 0.125rem;
}

.container > .btn > a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
  transform: skewX(45deg) translateX(0);
  transition: 0.75s;
}

.container > .btn::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.3125rem; /* Converted from -0.5rem */
  width: 0rem;
  height: 0.625rem; /* Converted from 1rem */
  background: rgb(80, 12, 12);
  border-radius: 0.625rem; /* Converted from 1rem */

  transition: 0.75s;
}

.container > .btn:hover::before {
  bottom: 0;
  height: 40%;
  width: 80%;
  border-radius: 1.875rem; /* Converted from 3rem */
  transition-delay: 0.25s;
}

.container > .btn::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  top: -0.3125rem; /* Converted from -0.5rem */
  width: 0rem;
  height: 0.625rem; /* Converted from 1rem */
  background: #f00;
  border-radius: 0.625rem; /* Converted from 1rem */
  transition: 0.75s;
}

.container > .btn:hover::after {
  top: 0;
  height: 50%;
  width: 80%;
  border-radius: 1.875rem; /* Converted from 3rem */
  transition-delay: 0.25s;
}

.container > .btn:nth-child(1)::before,
.container > .btn:nth-child(1)::after,
.container > .btn:nth-child(2)::before,
.container > .btn:nth-child(2)::after,
.container > .btn:nth-child(3)::before,
.container > .btn:nth-child(3)::after,
.container > .btn:nth-child(4)::before,
.container > .btn:nth-child(4)::after,
.container > .btn:nth-child(5)::before,
.container > .btn:nth-child(5)::after {
  background: #C6E894;
  box-shadow: 0 0 0.3125rem #758b55,
              0 0 0.9375rem #667a49,
              0 0 1.875rem #55663b, 
              0 0 3.75rem #607145; 
}

@media screen and (max-width: 480px) {
  html {
    font-size: 50%;
  }

  .review-title1 {
    font-size: clamp(0.9375rem, 4vw, 2.3rem);
    padding: 1.25rem 0.625rem; /* Converted from 2rem 1rem */
  }

  .container {
    padding: 3.125rem 0.625rem; /* Converted from 5rem 1rem */
    flex-wrap: wrap;
    gap: 0.625rem; /* Converted from 1rem */
  }

  .container > .btn {
    width: 5.625rem; /* Converted from 9rem */
    height: 5.625rem; /* Converted from 9rem */
    margin: 0.625rem; /* Converted from 1rem */
    padding: 0 2.5rem; /* Converted from 0 4rem */
  }

  .container > .btn > a {
    font-size: 1rem; /* Converted from 1.6rem */
    border-radius: 1.25rem; /* Converted from 2rem */
  }
  .container > .btn::before,
  .container > .btn::after {

    height: 0.5rem; /* Converted from 0.8rem */

  }
}
@media screen and (max-width: 360px) {
  html {
    font-size: 50%;
  }

  .review-title1 {
    font-size: clamp(0.9375rem, 4vw, 3.5625rem); 
    padding: 1.25rem 0.625rem; /* Converted from 2rem 1rem */
  }

  .container {
    padding: 3.125rem 0.625rem; /* Converted from 5rem 1rem */
    flex-wrap: wrap;
    gap: 0.625rem; /* Converted from 1rem */
  }

  .container > .btn {
    width: 5.625rem; /* Converted from 9rem */
    height: 5.625rem; /* Converted from 9rem */
    margin: 0.625rem; /* Converted from 1rem */
    padding: 0 2.5rem; /* Converted from 0 4rem */
  }

  .container > .btn > a {
    font-size: 1rem; /* Converted from 1.6rem */
    border-radius: 1.25rem; /* Converted from 2rem */
  }

  .container > .btn::before,
  .container > .btn::after {

    height: 0.5rem; /* Converted from 0.8rem */

  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    padding: 4.375rem 1.25rem; /* Converted from 7rem 2rem */
    flex-wrap: wrap;
    gap: 0.9375rem; /* Converted from 1.5rem */
    justify-content: center;
  }
  .container > .btn {
    width: 5.9375rem; /* Converted from 9.5rem */
    height: 5.9375rem; /* Converted from 9.5rem */
    margin: 0.9375rem; /* Converted from 1.5rem */
    padding: 0 3.125rem; /* Converted from 0 5rem */
  }

  .container > .btn > a {
    font-size: 1.0625rem; /* Converted from 1.7rem */
    border-radius: 1.5625rem; /* Converted from 2.5rem */
  }

  .review-title1 {
    font-size: clamp(1.25rem, 4.5vw, 1.875rem); /* Converted from 2rem, 3rem */
    padding: 1.5625rem 0.9375rem; /* Converted from 2.5rem 1.5rem */
  }

  .container > .btn::before,
  .container > .btn::after {
    height: 0.5625rem; /* Converted from 0.9rem */

  }
}
@media screen and (min-width: 769px) and (max-width: 835px) {
  .container {
    padding: 4.375rem 1.25rem; /* Converted from 7rem 2rem */
    flex-wrap: wrap;
    gap: 0.9375rem; /* Converted from 1.5rem */
    justify-content: center;
  }
  .container > .btn {
    width: 5.9375rem; /* Converted from 9.5rem */
    height: 5.9375rem; /* Converted from 9.5rem */
    margin: 0.9375rem; /* Converted from 1.5rem */
    padding: 0 3.125rem; /* Converted from 0 5rem */
  }

  .container > .btn > a {
    font-size: 1.0625rem; /* Converted from 1.7rem */
    border-radius: 1.5625rem; /* Converted from 2.5rem */
  }

  .review-title1 {
    font-size: clamp(1.25rem, 4.5vw, 1.875rem); /* Converted from 2rem, 3rem */
    padding: 1.5625rem 0.9375rem; /* Converted from 2.5rem 1.5rem */
  }

  .container > .btn::before,
  .container > .btn::after {
    height: 0.5625rem; /* Converted from 0.9rem */

  }
}
@media screen and (min-width: 836px) and (max-width: 992px) {
  .container {
    padding: 4.375rem 1.25rem; /* Converted from 7rem 2rem */
    flex-wrap: wrap;
    gap: 0.9375rem; /* Converted from 1.5rem */
    justify-content: center;
  }
  .container > .btn {
    width: 5.9375rem; /* Converted from 9.5rem */
    height: 5.9375rem; /* Converted from 9.5rem */
    margin: 0.9375rem; /* Converted from 1.5rem */
    padding: 0 3.125rem; /* Converted from 0 5rem */
  }

  .container > .btn > a {
    font-size: 1.0625rem; /* Converted from 1.7rem */
    border-radius: 1.5625rem; /* Converted from 2.5rem */
  }

  .review-title1 {
    font-size: clamp(1.25rem, 4.5vw, 1.875rem); /* Converted from 2rem, 3rem */
    padding: 1.5625rem 0.9375rem; /* Converted from 2.5rem 1.5rem */
  }

  .container > .btn::before,
  .container > .btn::after {
    height: 0.5625rem; /* Converted from 0.9rem */

  }
}




