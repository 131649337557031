.amcat-container {
    font-family: 'Poppins', sans-serif;
    padding: 30px;
    background-color: #f9f9f9;
  }
  
  .amcat-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* .amcat-tabs {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 70px;
  } */
  
  .tab {
    padding: 10px 20px;
    border: 1px solid #A8D85D;
    border-radius: 0px;
    background-color: #A8D85D;
    cursor: pointer;
    font-weight: 1000;
    font-size: 1.5em;
    color: #ffffff;
  }
  
  .tab.active {
    background-color: #3C5C7B;
    border-color: #3C5C7B;
    color: #ffffff;
  }
  
  .amcat-subtitle {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .amcat-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    color: #3C5C7B;
  }
  
  .amcat-table th,
  .amcat-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    font-size: 1.2em;
  }
  
  .amcat-table th {
    background-color: #ffffff;
    color: #3C5C7B;
    font-weight: bolder;
    font-weight: 5em;
    font-size: 1.6em;
  }
  
  .amcat-table tr:nth-child(even) {
    background-color: #F1F1F1;
  }
  
  .amcat-important-details {
    margin-top: 20px;
    font-size: 1.8em;
  }
  
  .amcat-important-details ul {
    list-style-type: none;
    padding: 0;
  }
  
  .amcat-important-details li {
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-size: 1em;
  }
  .important-details-title{
    font-size: 1em;
  }
  .amcat-important-details li::before {
    /* content: "✔️"; */
    color: green;
    margin-right: 10px;
    
  }
  .important-details-title{
    font-size: 1em;
  }

  .important-details {
    font-weight: bold; /* Make the text bold */
  }
  

  .back-button1 {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    font-size: 16px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-button1:hover {
    background-color: #e0e0e0;
  }

  .amcat-important-details h3{
    font-size: 3em;
  }
  .important-details-title{
    font-size: 2em;
  }
  .amcat-important-details1{
    font-size: 1.2em;
  }


  /* Mobile View Adjustments */
@media screen and (max-width: 768px) {

  .amcat-container {
    padding: 10px; /* Reduced padding on smaller screens */
  }

  .amcat-title {
    font-size: 1.5rem; /* Reduced font size */
  }

  .amcat-tabs {
    margin-top: 30px; /* Adjusted margin */
    gap: 0px; /* Reduced gap */
    flex-direction: column; /* Stack tabs vertically */
  }

  .tab {
    font-size: 1.1em; /* Adjusted font size */
    padding: 10px 20px;
  }

  .amcat-table th,
  .amcat-table td {
    font-size: 1em; /* Adjusted font size for readability */
    padding: 8px; /* Adjusted padding */
  }

  .amcat-table th {
    font-size: 1.3em; /* Adjusted font size for table headers */
  }

  .amcat-important-details h3 {
    font-size: 2.3rem; /* Adjusted font size */
  }

  .amcat-important-details li {
    font-size: 1em; /* Adjusted font size for list items */
  }

  .back-button1 {
    font-size: 14px; /* Adjusted font size for the back button */
    padding: 4px 8px; /* Adjusted padding */
  }
  .amcat-tabs {
    margin-top: 0px; /* Adjusted margin for better spacing */
    gap: 1px; /* Reduced gap between tabs */
    flex-direction: column; /* Align the tabs in a row */
    justify-content: center; /* Center align the tabs */
  }

  .tab {
    font-size: 0.4em; /* Adjusted font size */
    padding: 1px 2px;
    margin: 0 5px; /* Reduced margin between each tab */
  }

  .important-details-title{
    font-size: em;
  }

  .amcat-important-details h3 {
    font-size: 1.2em;
    margin-bottom: 15px;
  }
}


@media screen and (min-width: 769px) and (max-width: 1024px) {

  /* Container adjustments */
  .amcat-container {
    padding: 30px; /* Increased padding for spacing */
    max-width: 1200px; /* Ensure content is not too wide */
    margin: 0 auto; /* Center content */
  }

  /* Title styling */
  .amcat-title {
    font-size: 2rem; /* Larger font size for better visibility */
    text-align: center; /* Center title */
    margin-bottom: 20px; /* Space below the title */
  }

  /* Tab container adjustments */
  .amcat-tabs {
    margin-top: 40px; /* Adjusted margin for proper spacing */
    gap: 0px; /* Increased gap between tabs */
    flex-direction: row; /* Align tabs horizontally */
    justify-content: center; /* Center tabs horizontally */
    flex-wrap: wrap; /* Allow wrapping if needed */
  }

  /* Tab adjustments */
  .tab {
    font-size: 1.1em; /* Adjusted font size */
    padding: 12px 25px; /* Increased padding for better touch targets */
    margin: 5px; /* Added margin for spacing between tabs */
    background-color: #f4f4f4; /* Light background color for contrast */
    border: 1px solid #ccc; /* Light border for definition */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
  }

  .tab:hover {
    background-color: #e0e0e0; /* Slightly darker background on hover */
  }

  /* Table adjustments */
  .amcat-table th, .amcat-table td {
    font-size: 2.1em; /* Adjusted font size for readability */
    padding: 12px; /* Increased padding for better readability */
    text-align: left; /* Ensure text aligns properly */
  }

  .amcat-table th {
    font-size: 1.6em; /* Slightly larger font for header */
    background-color: #f8f8f8; /* Light background for table header */
    font-weight: bold; /* Bold header */
  }

  /* Important details adjustments */
  .amcat-important-details h3 {
    font-size: 2rem; /* Larger font size for important details headings */
    margin-bottom: 15px; /* Space below the heading */
  }

  .amcat-important-details li {
    font-size: 1em; /* Slightly larger font size for list items */
    margin-bottom: 10px; /* Space between list items */
  }

  /* Back button adjustments */
  .back-button1 {
    font-size: 16px; /* Increased font size */
    padding: 8px 20px; /* Increased padding for better interaction */
    background-color: #007bff; /* Background color */
    color: white; /* Text color */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }

  .back-button1:hover {
    background-color: #0056b3; /* Darker background on hover */
  }

  /* Adjusting layout for better responsiveness */
  .amcat-container1, .amcat-tabs, .amcat-table {
    width: 100%; /* Ensure they take full width available */
    box-sizing: border-box; /* Prevent overflow */
  }

  .amcat-important-details{
    font-size: 1.3em;
    margin-bottom: 15px;
  }
}
