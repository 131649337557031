.atcoder-page {
  font-family: Poppins, sans-serif;
  max-width: 1000px;
}

.atcoder-page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensures that the page takes full available height */
}

.atcoder-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.Atcodercover
{
  background: linear-gradient(to bottom, #ffffff, #e5fbc3); 
}
.atcoder-header {
  text-align: center;
}

.atcoder-header h1 {
  font-size: 2.5rem;
  color: #333;
}

.atcoder-description {
  font-size: 1.2rem;
  color: rgb(85, 85, 85);
}

.atcoder-filters {
  display: flex;
  justify-content: center; /* Centering the filters horizontally */
  margin-top: 20px;
}

.atcoder-filter-group {
  /* display: flex; */
  align-items: center;
  margin: 0 20px; /* Add space between the filter groups */
}

.atcoder-filter-group label {
  font-weight: bold;
  margin-right: 10px;
  color: #2b2b2b; /* Navy Blue for visibility */
  font-size: 1.2em;
}

.atcoder-filter-group select {
  padding: 5px;
  font-size: 1rem;
  color: #000080; /* Navy Blue for visibility */
  border: 0.2px solid #21232a; /* Green border */
  border-radius: 5px; /* Rounded corners */
  background-color: #f8f9fa; /* Light background color */
  transition: border-color 0.3s ease; /* Smooth border transition */
}

.atcoder-filter-group select:hover {
  border-color: #218838; /* Darker green on hover */
}


.atcoder-loading {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 30px;
}


.atcoder-problem-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
  transition: background-color 0.3s;
}

.atcoder-problem-item:hover {
  background-color: #f4f4f4;
}

.atcoder-problem-link {
  font-size: 1.2rem;
  color: #151515;
  text-decoration: none;
}

.atcoder-problem-link:hover {
  text-decoration: underline;
}

.atcoder-problem-rating {
  font-size: 1rem;
  color: #333;
}

.atcoder-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.atcoder-pagination-btn {
  padding: 12px 25px;
  font-size: 1.2rem;
  background-color: #3C5C7B; /* Blueish Green */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px; /* Adds some space between the buttons */
  transition: background-color 0.3s ease;
}

.atcoder-pagination-btn:hover {
  background-color: #0E2031; /* Dark Green on hover */
}

.atcoder-pagination-btn:disabled {
  background-color: #3C5C7B; /* Light Green */
  cursor: not-allowed;
}

.atcoder-page-info {
  font-size: 1.1rem;
  color: #3C5C7B; /* Blueish Green */
  margin: 0 10px; /* Adjusted margin for better spacing */
}


.atcoder-problem-item:hover {
  background-color: #D3F998; /* Light Green on hover */
}

.atcoder-filter-group select:hover {
  background-color: #D3F998; /* Light green background on hover */
}

.atcoder-problem-list {
  margin-top: 10px;
  background-color: #3c5b7b05; /* Light grey background for the entire problem list */
  padding: 100px; /* Optional: Adds some padding inside the container */
  border-radius: 8px; /* Optional: Rounds the corners */
}




@media (max-width: 768px) {
  .atcoder-header h1 {
    font-size: 3.5rem; /* Adjust header font size */
  }

  .atcoder-description {
    font-size: 2.4rem; /* Adjust description font size */
  }
  .atcoder-problem-item {
    font-size: 3.1rem; /* Adjust problem item font size */
  }

  .atcoder-filter-group label {
    font-weight: bold;
    margin-right: 10px;
    color: #2b2b2b; /* Navy Blue for visibility */
    font-size: 2em;
  }

  .atcoder-filters {
    justify-content: center;
    margin-top: 20px;
}

.atcoder-filter-group {
  align-items: center;
  margin: 0 20px;
}
  
  .atcoder-filter-group select {
    padding: 5px;
    font-size: 2rem;
    border: 0.2px solid #21232a; /* Green border */
    border-radius: 5px; /* Rounded corners */
    background-color: #f8f9fa; /* Light background color */
    transition: border-color 0.3s ease; /* Smooth border transition */
  }
  .atcoder-pagination{
    font-size: 2em;
  }

  .atcoder-problem-list {
    margin-top: 10px;
    background-color: #3c5b7b05; /* Light grey background for the entire problem list */
    padding: 30px; /* Optional: Adds some padding inside the container */
    border-radius: 8px; /* Optional: Rounds the corners */
  }
}