.amcat-container {
    font-family: 'Poppins', sans-serif;

    background-color: #f9f9f9;
  }
  
  .amcat-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 0px;
  }
  
  .amcat-tabs {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 70px;
  }
  
  .tab {
    padding: 10px 40px;
    border: 1px solid #A8D85D;
    border-radius: 0px;
    background-color: #A8D85D;
    cursor: pointer;
    font-weight: 1000;
    font-size: 1em;
    color: #ffffff;
  }
  
  .tab.active {
    background-color: #3C5C7B;
    border-color: #3C5C7B;
    color: #ffffff;
  }
  
  .amcat-subtitle {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .amcat-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    color: #3C5C7B;
  }
  
  .amcat-table th,
  .amcat-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    font-size: 1em;
  }
  
  .amcat-table th {
    background-color: #ffffff;
    color: #3C5C7B;
    font-weight: bolder;
    font-weight: 5em;
    font-size: 1em;
  }
  
  .amcat-table tr:nth-child(even) {
    background-color: #F1F1F1;
  }
  
  .amcat-important-details {
    margin-top: 20px;
  }
  
  .amcat-important-details h3 {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  
  .amcat-important-details ul {
    list-style-type: none;
    padding: 0;
  }
  
  .amcat-important-details li {
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-size: 1.2em;
  }
  
  .amcat-important-details li::before {
    /* content: "✔️"; */
    color: green;
    margin-right: 10px;
    
  }
  
  .amcat-container1{
    padding: 30px;
  }

  .back-button1 {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    font-size: 16px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-button1:hover {
    background-color: #e0e0e0;
  }
  


  /* Mobile View Adjustments */
@media screen and (max-width: 768px) {

  /* Adjust title font size */
  .amcat-title {
    font-size: 2rem;  /* Reduce the font size */
    margin-bottom: 0px;  /* Reduce the margin */
  }
  .amcat-container1{
    padding: 20px;
  }
  /* Adjust the tabs layout */
  .amcat-tabs {
    margin-top: 30px;  /* Adjust the margin */
    gap: 20px;  /* Reduce gap between tabs */

    justify-content: center;  /* Center the tabs */
    margin-bottom: 50px;  /* Adjust bottom margin */
    font-size: 1em;
  }

  /* Adjust tab styling */
  .tab {
    padding: 8px 20px;  /* Adjust padding */
    font-size: 2em;  /* Reduce font size */
    margin: 10 15px;  /* Add slight margin between tabs */
  }

  /* Active tab adjustments */
  .tab.active {
    background-color: #3C5C7B;
    border-color: #3C5C7B;
  }

  /* Adjust subtitle font size */
  .amcat-subtitle {
    font-size: 2rem;  /* Reduce font size */
    margin-bottom: 15px;  /* Adjust bottom margin */
  }

  /* Adjust table font size and padding */
  .amcat-table th, .amcat-table td {
    font-size: 1em;  /* Reduce font size */
    padding: 2px;  /* Reduce padding */
  }

  .amcat-table th {
    font-size: 1em;  /* Adjust font size for header */
    font-weight: bold;  /* Ensure header is bold */
  }

  /* Adjust the important details font size */
  .amcat-important-details h3 {
    font-size: 1rem;  /* Reduce font size */
    margin-bottom: 15px;  /* Adjust bottom margin */
  }

  .amcat-important-details li {
    font-size: 1em;  /* Reduce font size */
  }

  /* Adjust back button */
  .back-button1 {
    font-size: 14px;  /* Reduce font size */
    padding: 4px 8px;  /* Adjust padding */
  }

  .back-button1:hover {
    background-color: #e0e0e0;
  }
}


@media screen and (min-width: 769px) and (max-width: 1024px) {

  /* Container adjustments */
  .amcat-container1 {
    padding: 40px; /* Increased padding for spacing */
    max-width: 1200px; /* Ensure content is not too wide */
    margin: 0 auto; /* Center content */
  }

  /* Title styling */
  .amcat-title {
    font-size: 3rem; /* Larger font size for better visibility */
    text-align: center; /* Center title */
    margin-bottom: 20px; /* Space below the title */
  }

  /* Tab container adjustments */
  .amcat-tabs {
    margin-top: 40px; /* Adjusted margin for proper spacing */
    gap: 0px; /* Increased gap between tabs */
    flex-direction: row; /* Align tabs horizontally */
    justify-content: center; /* Center tabs horizontally */
    flex-wrap: wrap; /* Allow wrapping if needed */
  }


  /* Table adjustments */
  .amcat-table th, .amcat-table td {
    font-size: 1em; /* Adjusted font size for readability */
    padding: 12px; /* Increased padding for better readability */
    text-align: left; /* Ensure text aligns properly */
  }


  /* Important details adjustments */
  .amcat-important-details h3 {
    font-size: 1.3rem; /* Larger font size for important details headings */
    margin-bottom: 15px; /* Space below the heading */
  }

  .amcat-important-details li {
    font-size: 2em; /* Slightly larger font size for list items */
    margin-bottom: 10px; /* Space between list items */
  }

}
