
html {
  font-size: 100%;
  box-sizing: border-box;
}

.home {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  width: 100%;
}

.text-white {
  color: white;
  font-size: 1.8rem;
}

.text-white2, .text-green {
  font-size: clamp(2.3rem, 2vw, 1rem);
  color: white;
}




.hero {
  background-image: url('/public/images/Home.png');
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  padding: 3.9rem 1.25rem 9.375rem;
  min-height: 60vh;
  width: 100vw;
  flex-wrap: wrap;
  gap: 1.875rem;
}

.hero-left {
  margin-left: 6.25rem;
  margin-top: 1.875rem;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}



.hero-right {
  flex: 1;
  min-width: 18.75rem;
  max-width: 31.25rem;
  margin: 1.25rem;
  padding: 1.2rem;
  background-color: #3C5C7B;
  color: #ffffff;
  border-radius: 1.875rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 1);
  margin-top: 4rem;
}

.email-error{
  color: red;
  font-size: 14px;
  margin-top: 5px;  
}

.phone-error{
  color: red !important;
  font-size: 14px;
  margin-top: 5px;  
}

.hero-heading {
  text-align: center;
  font-size: clamp(1.25rem, 3vw, 1.875rem);
  margin-bottom: 1.4rem; 
  margin-top: -0.1rem;
}

.form {
  width: 100%;
  max-width: 85%;
  padding: 0 1.25rem 3.75rem;
  margin: 0 auto;
  background-color: #3C5C7B;
}


.hero {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: space-between; /* Space between the left and right sections */
  min-height: 500px; /* Ensure a minimum height for consistency */
}

.hero-left {
  flex: 1;
  /* Ensure the image fits within its container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-right {
  flex: 1;
  padding: 10px;
  /* Optionally add some styling to the form */
  margin-right: 100px;
}

.hero-image {
  width: 75%;
  height: auto;

}

/* Smaller size for images with 'small-image' class */
.small-image {
  max-width: 0%;  /* Adjust the size as per your need */
  height: auto;
}


.hero-left {
  flex: 1;
  /* Ensure the image fits within its container */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide overflow during animation */
}

.hero-image {
  animation: fadeInTop 2s ease-in-out; /* Add the fadeInTop animation */
}

/* Keyframes for fadeInTop effect */
@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-30px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}

/* Optional: If you want to apply the fade-out effect when the image changes, 
   you can use an extra class to add that effect */
.fade-out {
  animation: fadeOutTop 2s ease-in-out forwards;
}

@keyframes fadeOutTop {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px); /* Move the image upwards while fading */
  }
}



/* Floating bubble styles */
.floating-bubble {
  position: absolute;
  top: 20%;  /* Position the bubble above the hero section */
  left: 7%;  /* Adjust the left position as needed */
  padding: 10px 20px;
  background-color: #ff7f50;  /* Orange bubble color */
  color: white;
  border-radius: 50px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transform: translateX(-50%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: floatBubble 3s ease-in-out infinite, pulseBubble 1.5s infinite;
}

/* Floating effect (up and down) */
@keyframes floatBubble {
  0% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, -20px); /* Moves up */
  }
  100% {
    transform: translate(-50%, 0); /* Comes back down */
  }
}

/* Pulse effect for "What's New" text */
@keyframes pulseBubble {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); /* Slightly grow the bubble */
  }
  100% {
    transform: scale(1);
  }
}



@media (max-width: 480px) {
  .hero-left {
    width: 100%; /* Adjust width to fit smaller screens */
    top: 10%; /* Adjust top positioning */
  }
  .hero-image{
    margin-top: 100px;
  }

  .floating-bubble{
    font-size: 1.2rem;
    top: 19%;
    left: 14%; /* Adjust left positioning for smaller screens */
  }
}



@media (max-width: 769px) and (min-width: 481px) {
  .hero-left {
    width: 80%;  /* Adjust width for iPad */
    top: 5%;  /* Slightly adjust to fit within the tablet's screen */
  }
  .hero-image{
    margin-top: 100px;
    margin-right: 66px;
  }

  .floating-bubble  {
    font-size: 1.4rem;
    top: 15%;
    left: 10%;
  }
}

/* For large screens above 1024px */
@media (max-width: 1025x) and (min-width: 770px) {
  .hero-left {
    width: 60%; /* Adjust width for large screens */
    top: 8%;
  }
  .hero-image{
    margin-top: 600px;
    margin-right: 66px;
  }


  .floating-bubble{
    font-size: 1.5rem;
    top: 20%;
    left: 5%;
  }
}

.hero-right input[type="text"],
.hero-right input[type="email"],
.hero-right input[type="phone"],
.hero-right input[type="url"],
.hero-right textarea {
  border-radius: 0.5rem;
  padding: 1.125rem; 
  margin: 0.8rem auto;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 1);
  border: none;
  width: 95%; 
  font-size: 1rem;
  outline: none;
  background-color: #4f7085;
}
.program-selection {
  border-radius: 0.5rem;
  padding: 1.125rem; 
  margin: 1rem auto;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 1);
  border: none;
  width: 104%;
  font-size: 1rem;
  outline: none;
  background-color: #a1e739;
  color: #3C5C7B;
}

.stats-section {
  width: 74%;
  max-width: 67rem;
  min-height: 6rem; 
  margin: -6rem 0 0 auto;
  padding: 2rem;
  background-color: white;
  border-radius: 0.875rem;
  box-shadow: 0 0 0.3125rem rgba(14, 3, 3, 1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  transform: translateX(-7.8rem);
}

.item {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.5rem;
  flex: 1;
  min-width: 250px; 
}

.item img {
  width: 3.75rem;
  height: auto;
  margin-right: 0.625rem;
  flex-shrink: 0; /* Prevent image from shrinking */
}

.text {
  width: auto;
  font-size: 1.25rem;
  color: #333;
  font-weight: bold;
  line-height: 1.2; 
}

.callback-btn {
  background-color: #A8D85D;
  color: rgb(19, 0, 0);
  padding: 0.75rem;
  border: none;
  font-size: 1.3rem;
  border-radius: 1.5625rem;
  width: 80%;
  max-width: 15.625rem;
  margin: 3rem auto 0; 
  display: block; 
}
 /* @media (min-width:1400px) {

  .stats-section {
    transform: translateX(2.0rem);
    width: 100%;
    margin: -2rem auto 0;
  }
} */

/* @media (min-width:1400px) {

  .stats-section {
    transform: translateX(2.0rem);
    width: 100%;
    margin: -2rem auto 0;
  }
}  */
/* @media (max-width: 1200px) {
  .stats-section {
    transform: translateX(-5rem);
    width: 85%;
    margin: -3rem auto 0;
  }
} */

@media (max-width: 998px) {
  .hero-left {
    margin-left: 4rem;
  }

  .hero-right {
    max-width: 28rem; 
  }

  .stats-section {
    transform: translateX(-2.5rem);
    width: 80%;
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column; 
    padding: 5rem .3rem 5rem; 
    min-height: auto; 
    gap: 2rem; 
  }

  /* .hero-left {
    margin: 1rem auto; 
    width: 90%; 
    text-align: center; 
  } */
  /* .hero-image {
    width: 80%; 
    height: auto;
    max-width: 400px; 
    margin: 0 auto;
    display: block;
  } */

  .hero-right {
    max-width: 75%;
    width: 70%;
    margin: 1rem auto;
    padding: 1rem;
  }
  .program-selection{
    width: 89%;
    
  }
  .hero-right input[type="text"],
  .hero-right input[type="email"],
  .hero-right input[type="phone"],
  .hero-right input[type="url"],
  .hero-right textarea {
    width: 80%;
  }
  .stats-section {
    width: 80%;
    transform: translateX(-2);
  }

  .item {
    min-width: 45%;
    margin: 0.5rem 0;
  }
}

@media (max-width: 480px) {
  .hero {
    flex-direction: column; 
    padding: 2rem 1rem 17.5rem; 
    min-height: auto; 
    gap: -1rem; 
  }

  .hero-left {
    margin: 3rem auto; 
    /* width: 70%;  */
    text-align: center; 
  }
  /* .hero-image {
    width: 100%;
    height: auto;
    max-width: 450px; 
    margin: 6rem auto;
    display: block;
  } */
  .hero-right {
    max-width: 100%;
    width: 65%;
    padding: 1rem;
    margin: -4rem auto;
  }

  .program-selection{
    width: 89%;
    
  }
  .hero-right input[type="text"],
  .hero-right input[type="email"],
  .hero-right input[type="phone"],
  .hero-right input[type="url"],
  .hero-right textarea {
    width: 80%;
  }

  .stats-section {
    width: 88%;
    margin: -7.5rem 4.5rem 1.5rem;
    padding: 1rem;
    gap: 0.8rem;
  }

  .item {
    min-width: 100%;
    margin: 0.5rem 0;
  }
}
@media (max-width: 360px) {
  .hero {
    flex-direction: column; 
    padding: 2rem 1rem 18.5rem; 
    min-height: auto;
    gap: 1rem; 
  }

  .hero-left {
    margin: 1rem auto; 
    width: 90%; 
    text-align: center;
  }
  .hero-right {
    max-width: 100%;
    width: 65%;
    padding: 1rem;
    margin: -6rem auto;
  }
  .hero-image {
    width:80%;
    height: auto;
    max-width: 450px; 
    margin: 8rem auto;
    display: block;
  }

  .program-selection{
    width: 89%;
    
  }
  .hero-right input[type="text"],
  .hero-right input[type="email"],
  .hero-right input[type="phone"],
  .hero-right input[type="url"],
  .hero-right textarea {
    width: 80%;
  }

  .stats-section {
    width: 80%;
    margin: -7rem 6rem 1.5rem;
    padding: 1rem;
    gap: .0rem;
    transform: translateX(-2);
  }

  .item {
    min-width: 100%;
    margin: 0.5rem 0;
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .hero {
    flex-direction: column;
    padding: 3rem 1rem 6rem;
    min-height: auto;
    gap: 1.5rem;
  }

  /* .hero-left {
    margin: 1.5rem auto;
    width: 85%;
    text-align: center;
  } */

  .hero-right {
    max-width: 80%;
    width: 52.5%;
    padding: 1.2rem;
    margin: 1rem auto;
  }

  .program-selection {
    width: 75%;
  }

  .hero-right input[type="text"],
  .hero-right input[type="email"],
  .hero-right input[type="phone"],
  .hero-right input[type="url"],
  .hero-right textarea {
    width: 65%;
  }

  .stats-section {
    width: 80%;
    margin: -2.5rem 6.3rem 1rem;
    padding: 1.5rem;
    gap: 1rem;
  }

  .item {
    min-width: 45%;
    margin: 0.5rem 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding: 3rem 1rem 6rem;
    min-height: auto;
    gap: 1.5rem;
  }

  /* .hero-left {
    margin: 1.5rem auto;
    width: 85%;
    text-align: center;
  } */

  .hero-right {
    max-width: clamp(70%, 75%, 80%);
    width: clamp(55%, 58%, 60%);
    padding: 1.2rem;
    margin: 1rem auto;
  }

  .program-selection {
    width: clamp(75%, 77%, 79%);
    margin: 1rem auto;
  }

  .hero-right input[type="text"],
  .hero-right input[type="email"],
  .hero-right input[type="phone"],
  .hero-right input[type="url"],
  .hero-right textarea {
    width: clamp(65%, 68%, 70%);
    margin: 0.8rem auto;
    
  }

  .stats-section {
    width: 80%;
    margin: -2.3rem 5rem 1rem;
    padding: 1.5rem;
    gap: 1rem;
    
  }

  .item {
    min-width: 45%;
    margin: 0.5rem 0;
  }
  
}
@media (min-width: 999px) and (max-width: 1154px) {
  .hero {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  padding: 3.9rem .1rem 9.375rem;
  min-height: 60vh;
  width: 100%;
  flex-wrap: wrap;
  gap: 1.875rem;
  }

  .hero-left {
    margin-left: .1rem;
    margin-top: 1.875rem;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-right {
    max-width: 80%;
    width: 70%;
    padding: 1.2rem;
    margin: 4rem 3.8rem;
  }
  .hero-heading {
    text-align: center;
    font-size: clamp(1.25rem, 3vw, 1.7rem);
    margin-bottom: 1.4rem;  
    margin-top: -0.1rem;  
  }
  .program-selection {
    width: 75%;
  }

  .hero-right input[type="text"],
  .hero-right input[type="email"],
  .hero-right input[type="phone"],
  .hero-right input[type="url"],
  .hero-right textarea {
    width: 65%;
  }

  .stats-section {
    width: 80%;
    max-width: 90rem;
    min-height: 3rem; 
    margin: -6.2rem 0 0 auto;
    padding: 2rem;
    background-color: white;
    border-radius: 0.875rem;
    box-shadow: 0 0 0.3125rem rgba(14, 3, 3, 1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    transform: translateX(-5.4rem);
  }

  .item {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    padding: 0.5rem;
    flex: 1;
    min-width: 250px; 
  }
  
  .item img {
    width: 3.75rem;
    height: auto;
    margin-right: 0.625rem;
    flex-shrink: 0; 
  }
  
  .text {
    width: auto;
    font-size: 1.25rem;
    color: #333;
    font-weight: bold;
    line-height: 1.2; 
  }
}
@media (min-width: 1155px) and (max-width: 1280px) {
  .hero {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  padding: 3.9rem .1rem 9.375rem;
  min-height: 60vh;
  width: 100%;
  flex-wrap: wrap;
  gap: 1.875rem;
  }

  .hero-left {
    margin-left: .1rem;
    margin-top: 1.875rem;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-right {
    max-width: 50%;
    width: 50%;
    padding: 1.2rem;
    margin: 4rem 3.8rem;
  }
  .hero-heading {
    text-align: center;
    font-size: clamp(1.25rem, 3vw, 1.7rem);
    margin-bottom: 1.4rem;  
    margin-top: -0.1rem;  
  }
  .program-selection {
    width: 75%;
  }

  .hero-right input[type="text"],
  .hero-right input[type="email"],
  .hero-right input[type="phone"],
  .hero-right input[type="url"],
  .hero-right textarea {
    width: 65%;
  }

  .stats-section {
    width: 85%;
    max-width: 110rem;
    min-height: 3rem; 
    margin: -6.2rem 0 0 auto;
    padding: 2rem;
    background-color: white;
    border-radius: 0.875rem;
    box-shadow: 0 0 0.3125rem rgba(14, 3, 3, 1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    transform: translateX(-3.6rem);
  }

  .item {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    padding: 0.5rem;
    flex: 1;
    min-width: 250px; 
  }
  
  .item img {
    width: 3.75rem;
    height: auto;
    margin-right: 0.625rem;
    flex-shrink: 0; 
  }
  
  .text {
    width: auto;
    font-size: 1.25rem;
    color: #333;
    font-weight: bold;
    line-height: 1.2; 
  }
}
@media (min-width:1470px) {
  .stats-section{
    transform: translateX(-8.5rem);
    margin-top: -2.25rem;
    max-width: 90%;
  }
}
/* @media (min-width: 1281px) and (max-width: 1500px) {
  .hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 3.9rem 0 calc(9.375rem + 6rem); 
    min-height: 60vh;
    gap: 1.875rem;
  }

  .hero-left {
    margin-left: 0;
    width: 45%;
  }

  .hero-right {
    width: 45%;
    margin: 4rem 2rem;
  }

  .stats-section {
    position: absolute;
    bottom: calc(-16rem); 
    left: 70%;
    transform: translateX(-72.8%);
    width: calc(79% + 2vw);
    max-width: 100rem;
    min-height: min(6rem, 10vh);
    margin: 0;
    padding: clamp(1.5rem, 2vw, 2rem);
    background-color: white;
    border-radius: 0.875rem;
    box-shadow: 0 0 0.3125rem rgba(14, 3, 3, 1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: clamp(0.75rem, 1vw, 1rem);
  }

 
  .item {
    flex: 1 1 calc(25% - 2rem);
    min-width: min(250px, 25%);
    display: flex;
    align-items: center;
    gap: max(0.625rem, 1vw);
    padding: clamp(0.5rem, 1vw, 1rem);
  }

  .text {
    font-size: clamp(1rem, 1.25vw, 1.25rem);
    line-height: 1.2;
  }
} */
/* @media (min-width: 1281px) and (max-width: 00px) {
  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    padding: 3.9rem .1rem 9.375rem;
    min-height: 60vh;
    width: 100%;
    flex-wrap: wrap;
    gap: 1.875rem;
    }
  
    .hero-left {
      margin-left: .1rem;
      margin-top: 1.875rem;
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .hero-right {
      max-width: 50%;
      width: 50%;
      padding: 1.2rem;
      margin: 4rem 3.8rem;
    }
    .hero-heading {
      text-align: center;
      font-size: clamp(1.25rem, 3vw, 1.7rem);
      margin-bottom: 1.4rem;  
      margin-top: -0.1rem;  
    }
    .program-selection {
      width: 75%;
    }
  
    .hero-right input[type="text"],
    .hero-right input[type="email"],
    .hero-right input[type="phone"],
    .hero-right input[type="url"],
    .hero-right textarea {
      width: 65%;
    }
  

  .stats-section{
   
  transform: translateX(2.0rem);
  width: 100%;
  margin: -2rem auto 0; */
/* width: 76%; */
    /* max-width: 67rem;
    min-height: 6rem; */
    /* margin: -6rem 0 0 auto; */
    /* padding: 2rem;
    background-color: white;
    border-radius: 0.875rem;
    box-shadow: 0 0 0.3125rem rgba(14, 3, 3, 1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem; */
    /* transform: translateX(-7.3rem); */
  /* }
  
  .item {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    padding: 0.5rem;
    flex: 1;
  }
  
  .item img {
    width: 3.75rem;
    height: auto;
    margin-right: 0.625rem;
    flex-shrink: 0; 
  }
  
  .text {
    width: auto;
    font-size: 1.25rem;
    color: #333;
    font-weight: bold;
    line-height: 1.2;
  }
} */
/* @media (width: 1280px) {
  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    padding: 3.9rem 0 9.375rem;
    min-height: 60vh;
    width: 100%;
    flex-wrap: wrap;
    gap: 1.875rem;
  }

  .hero-left {
    margin-left: 0;
    margin-top: 1.875rem;
    width: 45%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-right {
    width: 45%;
    max-width: 31.25rem;
    padding: 1.2rem;
    margin: 4rem 2rem;
    background-color: #3C5C7B;
    border-radius: 1.875rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 1);
  }

  .hero-heading {
    text-align: center;
    font-size: 1.7rem;
    margin-bottom: 1.4rem;
    margin-top: -0.1rem;
  }

  .program-selection {
    width: 104%;
    margin: 1rem auto;
    padding: 1.125rem;
  }

  .hero-right input[type="text"],
  .hero-right input[type="email"],
  .hero-right input[type="phone"],
  .hero-right input[type="url"],
  .hero-right textarea {
    width: 95%;
    margin: 0.8rem auto;
    padding: 1.125rem;
  }

  .stats-section {
    width: 82%;
    max-width: 67rem;
    min-height: 6rem;
    margin: -4rem auto 0;
    padding: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    transform: translateX(-6rem);
  }

  .item {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    padding: 0.5rem;
    flex: 1;
    min-width: 250px;
  }

  .item img {
    width: 3.75rem;
    height: auto;
    margin-right: 0.625rem;
    flex-shrink: 0;
  }

  .text {
    width: auto;
    font-size: 1.25rem;
    color: #333;
    font-weight: bold;
    line-height: 1.2;
  }

  .callback-btn {
    width: 80%;
    max-width: 15.625rem;
    margin: 3rem auto 0;
  }
} */
/* @media (min-width: 1280px) and (max-width: 1500px) {
  .stats-section {
    position: relative;
    width: 76%;
    max-width: 67rem;
    transform: translateX(-7.8rem);
    margin: -4rem 0 0 auto;
    left: 0;
    right: 0;
  }
  .hero {
    position: relative;
    overflow: visible;
  }

  .item {
    flex: 1;
    min-width: 250px;
    max-width: calc(25% - 1rem);
  }
} */
/* @media (min-width: 1281px) and (max-width: 1441px) {
  .stats-section {
    width: 75%;
    max-width: 75rem;
    margin: -4rem auto 0;
    transform: translateX(-2rem);
    position: relative;
    left: 4rem;
  }

  .item {
    flex: 1;
    min-width: 250px;
    max-width: calc(25% - 1rem);
  }
} */

/* Smooth transition after 1441px */
/* @media (min-width: 1442px) {
  .stats-section {
    width: 74%;
    transform: translateX(-7.8rem);
  }
} */


/* Success Popup Styles */
.success-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.success-popup {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;
}

.success-icon {
  width: 60px;
  height: 60px;
  background: #4CAF50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  color: white;
  font-size: 32px;
  animation: scaleIn 0.3s ease-out;
}

.success-popup h3 {
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.success-popup p {
  color: #666;
  margin-bottom: 1.5rem;
}

.success-popup button {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

.success-popup button:hover {
  background: #45a049;
}

/* Phone error styles */
.phone-error {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.input-field.error {
  border-color: #dc3545;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0); }
  to { transform: scale(1); }
}