/* Main coder sheet container */
.coder-sheet {
  width: 100vw;
  /* max-width: 1500px; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(to bottom, #a9d85d4a, #ffffff);
}
/* Header Section */
.header1 {
text-align: center; /* Center the text content of the header */
display: flex;
flex-direction: column;
align-items: center; /* Center the Join Us button */
gap: 10px; /* Space between heading and button */
margin-top: 100px; /* Adjust spacing from the top */
}

/* Join Us Button */
.join-us-button5 {
background-color: #4CAF50; /* Green background */
color: white;
padding: 10px 20px;
font-size: 1.9em;
border: none;
border-radius: 5px;
cursor: pointer;
width: 200px; /* Set a fixed width */
}

/* Join Us Button */
.join-us-button5 {
background-color: #3C5C7B; /* Green background */
color: white;
padding: 15px 30px; /* Increase padding for a bigger button */
font-size: 1.2em; /* Increase font size for boldness */
font-weight: bold; /* Make the text bold */
border: none;
border-radius: 50px; /* Make the edges circular */
cursor: pointer;
width: auto; /* Let the width adjust according to content */
transition: background-color 0.3s ease; /* Smooth transition for hover effect */
margin-bottom: 10px;
}

.join-us-button5:hover {
background-color: #25394d; /* Darker green shade on hover */
}

.mentorship-program{
font-size: 1.5em;
}


.box2 {
width: 50%;
min-width: 900px;
height: 50px;
position: relative;
border-radius: 5px;
background: linear-gradient(to right, #9bc655 35%, #8caf57 100%);
margin-bottom: 20px; /* Adjusted for spacing */
padding: 10px 2px 0 00px;
color: rgb(9, 18, 18);
box-shadow: 1px 2px 1px -1px #777;
transition: background 200ms ease-in-out;
display: flex;
align-items: center;
margin-left: 20px;
}

/* Style for buttons with class 'page-button' */
.page-button5 {
background-color: #3C5C7B; /* Green background */
color: white;
padding: 15px 30px; /* Increase padding for a bigger button */
font-size: 1.5em; /* Increase font size for boldness */
font-weight: bold; /* Make the text bold */
border: none;
border-radius: 50px; /* Make the edges circular */
cursor: pointer;
width: auto; /* Let the width adjust according to content */
transition: background-color 0.3s ease; /* Smooth transition for hover effect */
margin-bottom: 10px;
}

.page-button5:hover {
background-color: #3C5C7B; /* Darker shade on hover */
}


.shadow2 {
position: relative;
}

.shadow2::before {
z-index: -1;
position: absolute;
content: "";
bottom: 13px;
right: 27px;
width: 25%;
top: 0;
box-shadow: 0 15px 10px #777;
transform: rotate(4deg);
transition: all 150ms ease-in-out;
}

.box1:hover {
background: linear-gradient(to right, #abbd73 0%, #abbd73 100%);
}

.shadow2:hover::before {
transform: rotate(0deg);
bottom: 20px;
z-index: -10;
}



.image-section {
margin-left: 40px;
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
margin-top: 20px;
}

.rectangle-image {
width: 400px;
height: 250px;
border-radius: 10px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* Main coder sheet container */
.coder-sheet {
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
/* Optional background properties can be added if needed */
/* background-image: url('/public/images/bluedotedlines.png');
background-position: right bottom; 
background-repeat: no-repeat;
background-size: 1500px 600px;
padding-bottom: 450px;
z-index: 1;
background-blend-mode: overlay;
background-color: rgba(255, 255, 255, 0.5); */
}

/* Header styling */
.header h1 {
margin-top: 140px;
color: rgb(0, 0, 0);
font-size: 2em;
font-weight: bold;
margin-left: 40px;
}

/* Box2 styling */
.box2 {
width: 70%;
min-width: 600px;
height: 50px;
position: relative;
border-radius: 5px;
background: linear-gradient(to right, #9bc655 35%, #8caf57 100%);
margin-bottom: 20px;
padding: 10px 2px 0 00px;
color: rgb(9, 18, 18);
box-shadow: 1px 2px 1px -1px #777;
transition: background 200ms ease-in-out;
display: flex;
align-items: center;
margin-left: 20px;
}

/* Shadow effect for box2 */
.shadow2 {
position: relative;
}

.shadow2::before {
z-index: -1;
position: absolute;
content: "";
bottom: 13px;
right: 27px;
width: 25%;
top: 0;
box-shadow: 0 15px 10px #777;
transform: rotate(4deg);
transition: all 150ms ease-in-out;
}

/* Hover effect for box2 */
.box1:hover {
background: linear-gradient(to right, #abbd73 0%, #abbd73 100%);
}

.shadow2:hover::before {
transform: rotate(0deg);
bottom: 20px;
z-index: -10;
}

/* Image section styling */
.image-section {
margin-left: 40px;
display: flex;
justify-content: center;
align-items: center;
gap: 2%;
margin-top: 3%;
}

.rectangle-image {
width: 300px;
height: 250px;
border-radius: 10px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mentorpara1{
font-size: 1.2em;
padding:0px 120px;
}

/* Image Section */
.image-section {
display: flex;
justify-content: space-between;
gap: 20px;
margin-top: 20px;
}

.image-container {
display: flex;
flex-direction: column; /* Stack image and buttons vertically */
align-items: center;
width: 48%; /* Adjust width of each image container */
}

.rectangle-image {
width: 100%;
border-radius: 8px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-buttons {
display: flex;
flex-direction: column; /* Stack buttons vertically */
gap: 10px; /* Space between buttons */
margin-top: 10px;
}

.box2{
font-size: 1em;
}

.content2{
font-size: 0.9em;
padding: 30px;
}


.image-section5 {
display: flex; /* Enable Flexbox for parent container */
flex-wrap: wrap; /* Allow wrapping if needed */
justify-content: center; /* Center the content horizontally */
align-items: center; /* Center the content vertically */
padding: 20px; /* Optional: Add padding */
}

.image-container {
display: flex;
flex-direction: column; /* Stack the image and button vertically inside the container */
justify-content: center; /* Center the content vertically */
align-items: center; /* Center the content horizontally */
width: 70%;
max-width: 100px; /* Set a max width for the individual containers */
margin: 10px; /* Add space between the items */
}
.image-section5{
width: 100px;
}
.image-gallery {
display: flex;
justify-content: space-between;
gap: 0px;
flex-wrap: wrap;

}

.image-item {
position: relative;
width: 48%; /* Adjust width for better spacing */
text-align: center; /* Center the button below the image */
}

.image-thumbnail {
width: 60%;
height: 70%;
border-radius: 8px;
}

.action-button {
margin-top: 10px; /* Space between image and button */
background-color: #4CAF50;
color: white;
padding: 10px 10px;
border: none;
cursor: pointer;
border-radius: 5px;
}

.action-button:hover {
background-color: #45a049;
}



.rectangle-image {
width: 100%; /* Make the image responsive */
height: auto; /* Maintain aspect ratio */
border-radius: 8px; /* Optional: Add rounded corners */
}

.image-buttons {
margin-top: 10px; /* Add space between image and button */
}

.mentorship-description p{
font-size: 3em;
}


/* Mobile View - Adjustments for smaller screen sizes */
@media screen and (max-width: 768px) {
/* General adjustments for the body or container */

.coder-sheet {
  /* max-width: 1500px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(to bottom, #a9d85d4a, #ffffff);
}

.coding-platforms {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center them if needed */
  margin-top: 30px;
  padding: 10px;
}

.header1 {
  text-align: center; /* Center the text content of the header */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the Join Us button */
  gap: 10px; /* Space between heading and button */
  margin-top: 10px; /* Adjust spacing from the top */
}


/* .coder-sheet {
  width: 100%;
  padding: 20px;
} */
.join-us-button5{
  width: 200px;
  font-size: 2em;
}
.mentorpara1{
  padding: 10%;
  font-size: 2em;
}
.content2{
  font-size: 6em;
}

/* Header Styling */
.header h1 {
  margin-top: 100px; /* Adjusted for smaller screens */
  font-size: 1.3em; /* Adjusted for mobile */
  margin-left: 0;
  text-align: center;
  line-height: 1.4; /* Ensure readability */
}

/* Box Styling */
.box2 {
  width: 90%; /* Reduced width for mobile */
  min-width: 280px; /* Ensure it doesn't overflow */
  margin-left: auto;
  margin-right: auto; /* Center the box */
  font-size: 0.3em;
}

/* Image Section Adjustments - Stack images vertically */
.image-section {
  display: flex; /* Ensure it's a flex container */
  flex-direction: column; /* Stack images vertically */
  align-items: center; /* Center the images horizontally */
  gap: 10px; /* Add some space between images */
  justify-content: center;
}
.image-buttons{
  width: 300px;
}

.rectangle-image {
  width: 100%; /* Set the width to 100% for each image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Adjustments for the Back Button */
.back-button {
  position: fixed; 
  top: 10px; /* Make it closer to the top for mobile */
  left: 10px; /* Ensure it stays at the left */
  background-color: transparent;
  border: none;
  color: darkslategray;
  font-size: 16px;
  font-family: 'PT Mono', monospace;
}

.back-button:hover {
  text-decoration: underline;
}

/* Box 10 Styling Adjustments for Mobile */
.box10 {
  width: 80%; /* Adjust width for mobile */
  min-width: 240px; /* Ensure proper size */
  height: auto; /* Allow flexibility */
  padding: 10px;
  margin-bottom: 15px; /* Spacing for mobile */
  font-size: 0.9em; /* Adjust font size for mobile */
}

/* Content Adjustment */
.content1 {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9em;
  text-align: left;
  color: #333; /* Adjusted text color */
}

/* Container Adjustments */
.container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.6);
  background-position: center; /* Center the background */
  background-repeat: no-repeat;
  background-size: cover; /* Make sure the background covers the container */
  padding: 15px;
  height: auto;
  overflow: hidden;
}

.box2 {
  background-color: #f4f4f4;
  padding: 10px;
  margin: 7px 0;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(31, 54, 34, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.shadow2 {
  position: relative;
  font-size: 0.6em;
}

.shadow2::before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 10px; /* Adjust for mobile */
  right: 5px; /* Adjust for mobile */
  width: 25%;
  top: 0;
  box-shadow: 0 15px 10px #777;
  transform: rotate(4deg);
  transition: all 150ms ease-in-out;
}

.shadow:hover::before {
  transform: rotate(0deg);
  bottom: 15px;
  z-index: -10;
}

.mainTitle1{
  font-size: 2em;
}

.content2 {
  font-size: 2em;
  padding: 20px;
  text-align: left; /* Align text to the left */
}
.image-section10 {
  margin: 0 auto; /* Center horizontally */
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center horizontally within the flex container */
  align-items: center; /* Center vertically within the flex container */
  height: 100%; /* Ensure it takes the full height of its parent */
}  
.image-section1 {
  display: flex; /* Use flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  flex-direction: column; /* Stack elements vertically if needed */
  height: 100vh; /* Full viewport height for vertical centering */
  text-align: center; /* Center text */
  margin: 0 auto; /* Ensure the container is centered within its parent */
  padding: 20px; /* Optional: Add padding */
}

.image-container {
  display: flex;
  flex-direction: column; /* Stack image and button vertically */
  align-items: center; /* Center content horizontally */
  justify-content: center;
  text-align: center; /* Center the text inside the container */
  width: 100%;
  max-width: 300px; /* Optional: Set a max width for image containers */
}

.rectangle-image {
  width: 100%; /* Make the image responsive */
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional: Add rounded corners */
}
.textGreen{
  font-size: 1.4em;
  line-height: 1em;
}

.image-buttons {
  margin-top: 10px; /* Add space between image and button */
  flex-direction: column; /* Stack image and button vertically */
  align-items: center; /* Center content horizontally */
  justify-content: center;
  text-align: center; /* Center the text inside the container */
}
.image-gallery{
  padding-top: 30px;
}

.content2{
  font-size: 3em;
}
.image-item {
  position: relative;
  width: 100%; /* Adjust width as needed */
  text-align: center;
  display: block; /* Ensures elements stack vertically */
  margin-bottom: 10px; /* Adds spacing between elements */
}

.platform-card {
  background-color: #fafafa; /* Lighter background */
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1); /* Deeper shadow */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition */
}

}




@media screen and (min-width: 769px) and (max-width: 1024px) {
.tab {
  font-size: 2.1em; /* Adjusted font size */
  padding: 12px 25px; /* Increased padding for better touch targets */
  margin: 5px; /* Added margin for spacing between tabs */
  background-color: #f4f4f4; /* Light background color for contrast */
  border: 1px solid #ccc; /* Light border for definition */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.tab:hover {
  background-color: #e0e0e0; /* Slightly darker background on hover */
}
/* .textGreen{
  font-size: 2em;
}*/
.mainTitle1{
  font-size: 3em;
  padding: 0px 20px 0px 20px;

} 



/* Back button adjustments */
.back-button1 {
  font-size: 16px; /* Increased font size */
  padding: 8px 20px; /* Increased padding for better interaction */
  background-color: #88C057; /* Background color */
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.back-button1:hover {
  background-color: #0056b3; /* Darker background on hover */
}
.box2{
    width: 70%;
    min-width: 500px;
    height: 80px;
    position: relative;
    border-radius: 5px;
    color: #25394d;
}


.mentorpara1{
  font-size: 1.4em;
  padding: 50px;
}
.mentorship-program p{
  /* font-size: 3em; */
}
.image-gallery{
  padding: 20px;
}


.coding-platforms {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 20px;
  padding: 40px;
}

}





.coding-platforms {
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
  gap: 20px;
  padding: 30px;
}

.platform-card {
  background: rgba(34, 139, 34, 0.2); /* Semi-transparent green */
  border-radius: 12px;
  padding: 20px;
  width: 75%;
  text-align: center;
  cursor: pointer;
  backdrop-filter: blur(10px); /* Glass effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: transform 0.3s, box-shadow 0.3s;
}

.platform-card:hover {
  box-shadow: 0 12px 20px rgba(34, 139, 34, 0.3); /* Green glow on hover */
  transform: translateY(-5px);
}


.platform-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2); /* More prominent hover shadow */
}

.platform-card h3 {
  font-size: 1.5rem;
  color: #333;
}

.platform-card p {
  color: #777; /* Slightly lighter text color */
  font-size: 1rem;
}

.platform-card img {
  width: 60px;  /* Set logo size */
  height: auto;
  margin-bottom: 15px;  /* Space between logo and text */
  transition: opacity 0.3s;  /* Fade effect */
}

.platform-card:hover img {
  opacity: 0.8;  /* Slight fade effect on hover */
}


.platform-logo:hover {
  transform: scale(1.1); /* Optional: Scales the image up on hover */
}


.platform-card img {
  width: 200px;
  height: auto;
  margin-bottom: 15px;
  transition: opacity 0.3s;
}
 /* Blurs the CodersSheet page */


.popup-form-overlay {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, #a9d85d4a, #ffffff);  /* Subtle white overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px); /* Blurs the CodersSheet page */
}

.popup-form {
  position: absolute;
  top:10%;
  background: rgba(255, 255, 255, 0.9); /* Semi-transparent form */
  padding: 25px;
  border-radius: 10px;
  width:60%;
  height: auto;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2); /* Increased shadow for form */
}

.popup-form h2 {
  font-size: 28px; /* Bigger heading */
  font-weight: bold;
  margin-bottom: 20px;
  color: black;
}

.popup-form input,
.popup-form select,
.popup-form textarea {
  width: auto; /* Same width for all fields */
  margin:0 0;
  padding: 12px;
  font-size: 16px;
  color: black;
  background: white;
  
  border-radius: 5px;
  display: block;
  box-shadow: none; /* Removing input field shadow */
   /* Center align text inside input */
}


.popup-form input::placeholder,
.popup-form textarea::placeholder {
  color: black; /* Black placeholder text */
}

.popup-form button {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 12px;
  width: auto;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.popup-form button:hover {
  background: #45a049;
}
/* Form validation styles */
.form-group {
  position: relative;
  margin:20px 0 20px;
}
.back-button1{
  z-index: 1000;
}

.form-input {
  width: 100%;
  padding: 12px;
  
  border-radius: 5px;
  font-size: 16px;

}

.form-input.error {
  border-color: #ff4444;
  background-color: #fff2f2;
}

.error-message {
  color: #ff4444;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  left: 0;
  margin-top: 5px;
}

/* Custom Alert styles */
.custom-alert {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 15px 25px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
}

.alert-content {
  display: flex;
  align-items: center;
}

.alert-content p {
  margin: 0;
  font-size: 16px;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Mobile responsiveness for form elements */
@media screen and (max-width: 768px) {
  .popup-form {
    width: 80%;
    height: auto;
    padding: 20px;
  }

  .form-input,
  .form-input.error {
    font-size: 14px;
    padding: 10px;
  }

  .error-message {
    font-size: 11px;
  }

  .custom-alert {
    width: 90%;
    right: 50%;
    transform: translateX(50%);
    top: 10px;
  }
}
@media screen and (max-width: 480px) {
  .popup-form {
    top:13%;
    width: 80%;
    height:auto;
    padding: 2%;
  }
  .form-group{
    
    margin:3% 0;
  }
  .form-input,
  .form-input.error {
    font-size: 1.5rem;
    padding: 10px;
  }

  .error-message {
    font-size: 1.5rem;
  }

  .custom-alert {
    width: 90%;
    right: 50%;
    transform: translateX(50%);
    top: 10px;
  }
}



