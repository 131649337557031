.contest-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding:0;
}

.all-above {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(to bottom, #a9d85d5e, rgba(255, 255, 255, 0.2));
  overflow-x: hidden;
  padding:0 1%;
  
}

.all-above::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('/public/images/bluedotedlines.png');
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  z-index: -1;
  transform: rotate(0deg);
  background-blend-mode: overlay;
  opacity: 0.13;
}

.nav-return-btn {
  position: fixed;
  top: 20px;
  left: 20px;
  background-color: #88C057;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  z-index: 200;
  transition: all 0.2s ease;
}

.nav-return-btn:hover {
  background-color: #7ab04c;
  transform: translateY(-2px);
}

.contest-title {
  margin-top: 60px;
  text-align: center;
  font-size: 2.5rem;
  color: darkslategray;
  margin-bottom: 40px;
}

.search-containers {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 30px;
  padding: 0 15px;
}

.search-wrappers {
  position: relative;
  width: 100%;
}

.search-inputs {
  width: 90%;
  padding: 12px 40px;
  border-radius: 8px;
  border: 2px solid #e5e7eb;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  outline: none;
  transition: all 0.2s ease;
  color:black;
}

.search-inputs:focus {
  border-color: #88C057;
  box-shadow: 0 0 0 3px rgba(136, 192, 87, 0.2);
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6b7280;
  width: 20px;
  height: 20px;
}

.contests-section {
  width: 100%;
  margin-bottom: 40px;
}

.contests-grid {
  display: grid;
  gap: 24px;
  row-gap: 64px;
  width: 100%;
  max-width: 1425px;
  margin: 0 auto;
}

.contest-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.contest-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.contest-card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
}

.contest-card-header {
  margin-bottom: 16px;
}

.contest-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: #2d3748;
  margin-bottom: 8px;
  line-height: 1.4;
}

.contest-host {
  font-size: 1.1rem;
  color: #4a5568;
}

.contest-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contest-details p {
  margin: 0;
  line-height: 1.6;
}

.contest-actions {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.view-contest-btn {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.view-contest-btn.primary {
  background-color: #88C057;
  color: white;
}

.view-contest-btn.primary:hover {
  background-color: #7ab04c;
}

.view-contest-btn.secondary {
  background-color: #4299e1;
  color: white;
}

.view-contest-btn.secondary:hover {
  background-color: #3182ce;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 2rem auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  color: #dc2626;
}

.no-result {
  text-align: center;
  padding: 32px;
  color: #000000;
  font-size: 1.125rem;
}

/* Responsive Breakpoints */
@media (min-width: 1425px) {
  .contests-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1025px) and (max-width: 1424px) {
  .contests-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .contests-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    row-gap: 70px;
  }
  
  .contest-title {
    font-size: 2rem;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .contests-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    row-gap: 70px;
    margin:0 auto;
  }
  
  .contest-card {
    margin:0 auto;
    width:85%;

  }
  
  .contest-title {
    font-size: 1.8rem;
    margin-top: 40px;
    margin-bottom: 25px;
  }
  
  .search-containers {
    padding: 0;
  }
  .search-inputs{
    width:85%;
    margin:0;
  }
  .search-wrappers{
    margin:0 auto;
  }
  .contest-details p {
    font-size: 1.3rem;
    line-height: 1.4;
  }
}
.search-inputs::placeholder{
  color:black;
}
@media (max-width: 480px) {
  .contests-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    row-gap: 20px;
    margin:0 auto;
  }
  
  .contest-card {
    padding: 16px;
    width:70%;
    height:90%;
    margin:0 auto;
  }
  
  .contest-title {
    font-size: 2.5rem;
    margin-top: 57px;
    margin-bottom: 20px;
  }
  
  .contest-name {
    font-size: 2rem;
  }
  
  .contest-host {
    font-size: 2rem;
  }
  
  .contest-details p {
    font-size: 1.75rem;
    line-height: 1.4;
  }
  
  .search-inputs {
    font-size: 14px;
    width: 74.75%;
    margin:0;
   
  }
  .search-wrappers{
    margin:0 auto;
  }
  
  
  .contest-actions {
    gap: 10px;
    margin-top: 32px;
  }
  
  .view-contest-btn {
    padding: 10px 14px;
    font-size: 13px;
  }
  
  .nav-return-btn {
    top: 10px;
    left: 10px;
    padding: 8px 16px;
  }

  .all-above {
    width: 100vw;
    overflow-x: hidden;
  }
}